<hr class="line">
<footer>
  <div>
    <div class="links" *ngIf="showLinks">
        <a href="https://avation.com/privacy-policy">Privacy Policy</a>
        <a href="https://avation.com/terms-of-use">Terms of Use</a>
        <a href="https://avation.com/contact/">Contact</a>
        <a *ngIf="!isPatient()" routerLink="/acknowledgements">Acknowledgements</a>
        <a *ngIf="this.showAccount" routerLink="/delete-account">Account</a>
        <div *ngIf="isPatient()">
        <button  mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
        </button>
          <mat-menu #menu="matMenu" >
            <button mat-menu-item routerLink="/acknowledgements">Acknowledgements</button>
            <button mat-menu-item routerLink="/welcome/right-responsibility">
              <span>Patient Rights and Responsibilities</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="ComplianceContact">Compliance Contact</button>
            <button mat-menu-item [matMenuTriggerFor]="accountDeletion">Account Deletion</button>
          </mat-menu>
          <mat-menu #ComplianceContact="matMenu" yPosition="above">
              <span style="padding: 1em;width: fit-content;display: block;">In the event your complaint is not resolved to your satisfaction you can contact our accrediting organization The Compliance Team at <a style="display: inline;" target="_blank" href="https://www.thecomplianceteam.org">www.thecomplianceteam.org</a> or by calling 1-888-291-5353.</span>
            </mat-menu>
          <mat-menu #accountDeletion="matMenu" yPosition="above">
              <p style="padding: 1em;width: fit-content;display: block;">If you want to delete your Vivally System account, please send a request to <a href="mailto:customercare@avation.com" class="link">customercare&#64;avation.com</a>
              </p>
            </mat-menu>
        </div>
        </div>
    </div>
    <div class="right-footer">
        Vivally™ Cloud, VCG-WPN01, v{{ appVersion }}, API v{{apiVersion$ | async}}
        Copyright {{copyrightYear}} © Avation Medical
    </div>
</footer>