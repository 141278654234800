<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="dialog">
  <h2 mat-dialog-title>Delete {{data.name}}?</h2>
  <mat-dialog-content>Are you sure you want to delete {{data.name}}?</mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button class="push-button tertiary" mat-dialog-close="cancel()">Cancel</button>
    <button class="push-button primary" [mat-dialog-close]="true">Delete</button>
  </mat-dialog-actions>
</div>
