// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { ReportService } from "src/app/core/services/report.service";
import { APIDataSource } from "src/app/shared/helpers/api-data-source";
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'clinician-snapshot-report',
  templateUrl: './clinician-snapshot-report.component.html',
  styleUrls: ['./clinician-snapshot-report.component.scss']
})
export class ClinicianSnapshotReport implements OnInit {

  date: Date = new Date();
  dataSource: APIDataSource<any>;
  clinician: any;
  displayedColumns: string[];
  numberOfPatients: number;

  constructor(
    public readonly reportService: ReportService,
    public readonly datePipe: DatePipe,
    public readonly authService: AuthService,
    private dialogRef: MatDialogRef<ClinicianSnapshotReport>
  ) { }

  ngOnInit(): void {
    this.reportService.getCallback().subscribe(res => {
      this.dataSource = res;
      this.numberOfPatients = res.length;
    });
    this.clinician = this.reportService.getUserDetails();
    this.displayedColumns = this.reportService.getColumns();
  }

  getUsername() {
    return this.authService.getName();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}