// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FluidUnit } from 'src/app/shared/enums/fluid-unit';
import { Navigation } from 'src/app/shared/enums/navigation';


export interface SortDetails {
  active: string;
  direction: string;
}


@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  navToggle: string = 'dehaze';

  private sidebarOpenSubject = new BehaviorSubject<boolean>(false);
  sidebarOpen$ = this.sidebarOpenSubject.asObservable();

  fluidUnit$: BehaviorSubject<FluidUnit> = new BehaviorSubject(null);

  constructor() { }

  setSidebarOpen(isOpen: boolean) {
    this.sidebarOpenSubject.next(isOpen);
    this.navToggle = isOpen ? 'close' : 'dehaze';
  }

  private getKey(page: Navigation, key: string) {
    return Navigation.toString(page) + ':' + key;
  }

  pageSize(): number {
    return parseInt(localStorage.getItem('pageSize'));
  }

  setPageSize(size: number): void {
    localStorage.setItem('pageSize', size.toString());
  }

  pageIndex(page: Navigation): number {
    return parseInt(sessionStorage.getItem(this.getKey(page, 'pageIndex')));
  }

  setPageIndex(page: Navigation, num: number): void {
    sessionStorage.setItem(this.getKey(page, 'pageIndex'), num.toString());
  }

  clearPageIndex(page: Navigation): void {
    sessionStorage.removeItem(this.getKey(page, 'pageIndex'));
  }

  sort(page: Navigation): SortDetails {
    let sortCol = sessionStorage.getItem(this.getKey(page, 'sortCol'));
    let sortDir = sessionStorage.getItem(this.getKey(page, 'sortDir'));

    if (!sortCol || !sortDir) {
      return null;
    }
    return {active: sortCol, direction: sortDir};
  }

  setSort(page: Navigation, sort: SortDetails): void {
    sessionStorage.setItem(this.getKey(page, 'sortCol'), sort.active);
    sessionStorage.setItem(this.getKey(page, 'sortDir'), sort.direction);
  }

  clearSort(page: Navigation): void {
    sessionStorage.removeItem(this.getKey(page, 'sortCol'));
    sessionStorage.removeItem(this.getKey(page, 'sortDir'));
  }

  filters(page: Navigation): object {
    return JSON.parse(sessionStorage.getItem(this.getKey(page, 'filters')));
  }

  setFilters(page: Navigation, filters: object): void {
    sessionStorage.setItem(this.getKey(page, 'filters'), JSON.stringify(filters));
  }

  updateFilters(page: Navigation, filters: object): void {
    let stored = this.filters(page);
    if (stored === null) {
      stored = {};
    }
    let newFilters = Object.assign(stored, filters);
    this.setFilters(page, newFilters);
  }

  clearFilters(page: Navigation): void {
    sessionStorage.removeItem(this.getKey(page, 'filters'));
  }

  lastVisitedUser(page: Navigation): number {
    return parseInt(sessionStorage.getItem(this.getKey(page, 'lastVisitedUser')));
  }

  setLastVisitedUser(page: Navigation, userId: number): void {
    sessionStorage.setItem(this.getKey(page, 'lastVisitedUser'), userId.toString());
  }

  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  setTab(tab):void{
    sessionStorage.setItem('tab',tab)
  }

  getTab(){
    return sessionStorage.getItem('tab')
  }
  
  fluidUnit(): FluidUnit {
    let retval = <FluidUnit>sessionStorage.getItem('fluidUnit');
    if (retval == null) {
      return FluidUnit.milliliters;
    }
    return retval;
  }

  setFluidUnit(unit: FluidUnit) {
    sessionStorage.setItem('fluidUnit', unit);
    this.fluidUnit$.next(unit);
  }
}
