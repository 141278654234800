// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export class PhoneNumber {
    get valid(): boolean {
        return this.area.length == 3 && this.exchange.length == 3 && this.subscriber.length == 4;
    }

    constructor(public area: string, public exchange: string, public subscriber: string, public extension: string = null) {
        if (this.extension === '') {
            this.extension = null;
        }
    }

    // Create phone number from string of formats '+15555555555x1234' or '555-555-5555 x1234'
    static fromString(input: string): PhoneNumber | null {
        if (!input) {
            return null
        }
        input = input.replace('-', '').replace('+', '');
        if (input.length < 10) {
            return null;
        }
        let ext = null;
        if (input.includes('x')) {
            let split = input.split('x');
            input = split[0].trim(), ext = split[1] ? split[1].trim() : null;
        }
        input = input.substring(input.length - 10);
        return new PhoneNumber(input.substring(0, 3), input.substring(3, 6), input.substring(6), ext);
    }

    toRawString(): string {
        return '+1' + this.area + this.exchange + this.subscriber;
    }

    toString(): string {
        return this.area + '-' + this.exchange + '-' + this.subscriber + (this.extension ? (' x' + this.extension) : '');
    }
}