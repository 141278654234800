// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UserPrescriptionService } from 'src/app/core/http/user-prescription/user-prescription.service';
import { UserService } from 'src/app/core/http/user/user.service';
import { User } from 'src/app/shared/models/user.interface';
import { StatusPopupComponent } from '../status-popup/status-popup.component';
import { AddConfirmDialogResult, UploadPrescriptionDialogData } from '../../dialog-data.interface';

@Component({
  selector: 'app-upload-prescription-modal',
  templateUrl: './upload-prescription-modal.component.html',
  styleUrls: ['./upload-prescription-modal.component.scss']
})
export class UploadPrescriptionModalComponent implements OnInit {

  user$: Observable<User>;
  file?: File;
  errorInvalidFileType: boolean = false;
  private acceptedFileTypeList: string[] = [ 'application/pdf' ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadPrescriptionDialogData, 
    private dialogRef: MatDialogRef<UploadPrescriptionModalComponent>, 
    private userService: UserService,
    private userPrescriptionService: UserPrescriptionService,
    private readonly dialog: MatDialog,
  ) {
    this.user$ = this.userService.read(data.userId);
  }

  ngOnInit(): void {
    this.dialogRef.beforeClosed().subscribe((result: AddConfirmDialogResult) => {
      if (result.success) {
        this.dialog.open(StatusPopupComponent , { data: {
          message: 'Prescription added successfully',
          buttonText: 'Close',
          color: false,
        }});
      }
      else if (result.response) {
        let message: any = "";
        Object.values(result.response.error).forEach(value => message = value);
        this.dialog.open(StatusPopupComponent , { data: {
          message: message,
          buttonText: 'Close',
          color: true,
        }});
      }
    })
  }

  fileChanged(event: any) {
    this.file = event?.target?.files?.[0] ?? null;
    this.errorInvalidFileType = false;

    if (this.file && this.acceptedFileTypeList.indexOf(this.file.type) === -1) {
      this.file = null;
      this.errorInvalidFileType = true;
    }
  }

  async submit() {
    this.userPrescriptionService.create({userId: this.data.userId}, this.file, this.data.removeExisting).toPromise().then(_ => {
      this.dialogRef.close({success: true} as AddConfirmDialogResult)
    }).catch(response => {
      this.dialogRef.close({success: false, response: response} as AddConfirmDialogResult);
    });
  }

  cancel() {
    this.dialogRef.close({ success: false, response: null } as AddConfirmDialogResult);
  }

  get acceptedFileTypes() {
    return this.acceptedFileTypeList.join(', ');
  }
}
