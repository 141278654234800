// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Pipe, PipeTransform } from '@angular/core';
import { FluidUnit } from '../enums/fluid-unit';

@Pipe({name: 'fluid'})
export class FluidPipe implements PipeTransform {
    transform(mL_value: number, unit: FluidUnit = FluidUnit.milliliters): number {
        let retval = mL_value;
        if (unit == FluidUnit.ounces) {
            retval = mL_value / 30;
        }
        else if (unit == FluidUnit.cups) {
            retval = mL_value / 240;
        }
        return +(retval.toFixed(2));
    }
}