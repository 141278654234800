// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinct, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageLoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private loads: number = 0;
  
  constructor() { }
  
  public get loading$() {
    return this.loadingSubject.asObservable().pipe(distinctUntilChanged());
  }

  public start() {
    this.loads++;
    this.update();
  }

  public end() {
    this.loads--;
    this.update();
  }

  public clear() {
    this.loads = 0;
    this.update();
  }

  private update() {
    if(this.loads > 0) {
      this.loadingSubject.next(true);
    }
    else {
      this.loadingSubject.next(false);
    }
  }

}
