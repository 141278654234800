// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private readonly http: HttpClient, private readonly appSettingsService: AppSettingsService) { }

  public send(userIds: number[], message: string) {
    let params = {
      userIds,
      message
    };
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/pushnotifications/send`, params))
    );
  }
}
