// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, OnDestroy, Optional, QueryList, Self, ViewChildren } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { integerValidator } from '../../validators';


@Component({
  selector: 'digit-box-input',
  templateUrl: 'digit-box-input.component.html',
  styleUrls: ['digit-box-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: DigitBoxInput}],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class DigitBoxInput implements OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
  @ViewChildren('digitInput') digitInputs: QueryList<ElementRef<HTMLInputElement>>;
  
  static nextId = 0;
  parts: FormArray;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  id = `digit-box-input-${DigitBoxInput.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};
  controlConfig = [null, [Validators.required, integerValidator()]];

  get empty() {
    this.parts.controls.forEach(x => {
      if (x.value == '') {
        return true;
      }
    });
    return false;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string {
    if (this.parts.invalid) {
      return null;
    }
    return this.parts.value.join('');
  }

  @Input()
  get count(): number {
    return this._count;
  }
  set count(v: number) {
    this._count = +v;
    this.parts = this._formBuilder.array(Array(this._count).fill(this.controlConfig));
  }
  private _count: number = 4;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get errorState(): boolean {
    if (this.empty) {
      return false;
    }
    return this.parts.invalid && this.touched;
  }

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _focusMonitor: FocusMonitor,
    private readonly _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      let target = event.target as HTMLInputElement;
      target.select();
      
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocus(idx: number): void {
    if (idx < 0 || idx >= this.parts.length) return;
    let focus = this.digitInputs.get(idx).nativeElement;
    if (!focus) return;
    this._focusMonitor.focusVia(focus, 'program');
    focus.select();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.digit-box-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(idx: number): void {
    let current = this.parts.at(idx);
    if (current.value && !current.errors) {
      this.autoFocus(idx+1);
    };
    this.onChange(this.value);
  }
}
