// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'get-the-app',
  templateUrl: './get-the-app.component.html',
  styleUrls: ['./get-the-app.component.scss']
})
export class GetTheAppComponent implements OnInit {
  displayPage: boolean = false;

  readonly link_iOS: string = 'https://apps.apple.com/us/app/vivally/id1585689224';
  readonly link_android: string = 'https://play.google.com/store/apps/details?id=com.avation.vivally';

  constructor(
    private readonly platform: Platform,
  ) 
  { }

  ngOnInit(): void {
    if (this.platform.IOS) {
      window.location.href = 'https://apps.apple.com/us/app/vivally/id1585689224';
    }
    else if (this.platform.ANDROID) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.avation.vivally';
      // window.open('market://details?id=com.avation.vivally', '_system');
    }
    else {
      this.displayPage = true;
    }
  }
}
