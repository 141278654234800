<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="report" id="report">
    <div class="report-header">
        <div class="logo">
            <img class="logo-icon" src="../../../assets/icons/ic_logo.png">
        </div>
        <div class="center">
            <div class="title">Report for {{patient.patientId || 'N/A'}}</div>
            <div class="user-data">
                <div><b>Initial Screening: </b>{{patient.enrollmentDate | date: 'MM/dd/yyyy':'UTC'}}</div>
                <div><b>Clinician: </b>{{patient.clinicianName}}</div>
            </div>
        </div>
        <div class="metadata">
            <div><b>Therapy Compliance: </b>{{patient.therapyCompliance | therapyCompliance: '1.0-0'}}</div>
            <div><b>Completed Sessions: </b>{{patient.completedSessions}}</div>
            <div><b>Target Session: </b>{{patient.completedSessions + patient.incompleteSessions}}</div>
            <div><b>Last Completed Session: </b>{{patient.lastSessionTime | date: 'MM/dd/yy hh:mm a':'UTC'}}</div>
        </div>
    </div>

    <div class="body">
        <h1 class="title">Therapy Sessions</h1>
        <div class="filter-display">{{reportService.getFilterStr()}}</div>
    </div>
    <div class="chart">
        <patient-therapy-graph [data]="chartData"></patient-therapy-graph>
    </div>

    <table mat-table [dataSource]="dataSource" fixedLayout="true">

        <ng-container matColumnDef="isScreening">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Therapy Session or Personalization</th>
            <ng-template *matCellDef="let row" [ngIf]="row.isScreening" [ngIfElse]="isSession">
                <td mat-cell>Personalization</td>
            </ng-template>
            <ng-template #isSession>
                <td mat-cell><mat-icon class="session-column-icon" svgIcon="vivally-light"></mat-icon></td>
            </ng-template>
        </ng-container>

        <ng-container matColumnDef="eventTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.eventTimestamp; else epoch;">
                    {{row.eventTimestamp | dateTimeOffset: 'MMM d, y, h:mm a'}}
                </ng-container>
                <ng-template #epoch>
                    {{row.startTime | date: 'MMM d, y, h:mm a':'UTC'}}
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row">{{row.isComplete | therapyStatus: row.isScreening}}</td>
        </ng-container>

        <ng-container matColumnDef="pauses">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pauses</th>
            <td mat-cell *matCellDef="let row">{{row.isScreening ? '-' : row.pauses}}</td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Therapy Duration</th>
            <td mat-cell *matCellDef="let row">{{row.isScreening ? '-' : (row.duration / 60 | number: '1.0-0') + ' Min'}}</td>
        </ng-container>

        <ng-container matColumnDef="foot">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Foot</th>
            <td mat-cell *matCellDef="let row">{{row.foot | foot: row.bothFeet}}</td>
        </ng-container>

        <ng-container matColumnDef="therapySchedule">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Therapy Schedule</th>
            <td mat-cell *matCellDef="let row">{{row.therapySchedule | therapySchedule}}</td>
        </ng-container>

        <ng-container matColumnDef="emgDetectionPointPulseWidth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PW at EMG Detect</th>
            <td mat-cell *matCellDef="let row">{{row.emgDetectionPointPulseWidth | number: '1.0-0'}}</td>
        </ng-container>

        <ng-container matColumnDef="targetEMGStrength">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>EMG Strength Target</th>
            <td mat-cell *matCellDef="let row">{{row.targetEMGStrength | number: '1.0-0'}}</td>
        </ng-container>

        <ng-container matColumnDef="overallEMGDetectPercentage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Overall EMG Detect%</th>
            <td mat-cell *matCellDef="let row">{{row.overallEMGDetectPercentage | number: '1.0-0'}}%</td>
        </ng-container>

        <ng-container matColumnDef="avgEMGStrength">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Avg EMG Strength</th>
            <td mat-cell *matCellDef="let row">{{row.avgEMGStrength | number: '1.0-0'}}</td>
        </ng-container>

        <ng-container matColumnDef="avgStimPulseWidth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Average Stim Pulse Width</th>
            <td mat-cell *matCellDef="let row">{{row.avgStimPulseWidth | number: '1.0-0'}}</td>
        </ng-container>

        <ng-container matColumnDef="overallAvgImpedance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Overall Avg Impedence</th>
            <td mat-cell *matCellDef="let row">{{row.overallAvgImpedance | number: '1.0-0'}}</td>
        </ng-container>

        <ng-container matColumnDef="batteryLevelAtStart">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Battery Level At Start</th>
            <td mat-cell *matCellDef="let row">{{row.batteryLevelAtStart | batteryLevelFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="generatePrescription">
            <th id="generatePrescription" mat-header-cell *matHeaderCellDef mat-sort-header>Prescription Needed?</th>
            <td mat-cell *matCellDef="let row">{{row.createPrescription ? 'Yes' : 'No'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="100%" style="text-align: center;">No data</td>
        </tr>
    </table>
</div>

<button id="exportButton" class="export-button" mat-raised-button (click)="reportService.exportReport()">Export</button>