// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { Export } from 'src/app/shared/enums/export';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  exportType: Export = null;
  exportParams: any;
  callbackFunction: any;

  constructor() { }

  setState(type: Export): void {
    this.exportType = type;
  }

  setParams(params: any): void {
    this.exportParams = params;
  }

  setCallback(fn: any): void {
    this.callbackFunction = fn;
  }

  callCallback() {
    this.callbackFunction();
  }
}
