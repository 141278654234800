import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumPrice'
})
export class SumPricePipe implements PipeTransform {
  transform(value: any,...args: any[]): number {
    if(args[0]=='percent'){
      let result=Number(value) *100
      return result
    }
    if(args[0] && args[1]=='division'){
      let result=Number(value)/Number(args[0])
      return Number(result.toFixed(2))
    }
    if(args[0] && args[1]=='add'){
      let result=Number(value)+Number(args[0])
      return Number(result.toFixed(2))
    }
    if(args[0] && args[1]=='multiply'){
      let result=Number(value) * Number(args[0])
      return Number(result.toFixed(2))
    }
    return value.map(item => {
      const price = parseFloat(item.metadata.originalItemPrice);
      if (!isNaN(price)) {
        return price * item.quantity;
      }
      return 0;
    }).reduce((acc, curr) => acc + curr, 0);
  }
}
