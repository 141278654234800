// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'therapySchedule'})
export class TherapySchedulePipe implements PipeTransform {
    transform(value: any, length: string = 'short'): any {
        if (value === null || value < 0) {
            return '-';
        }

        if (value == 14) {
            value = 0.5;
        }
        else if (value == 28) {
            value = 0.25;
        }

        return `${value}/week`
    }
}