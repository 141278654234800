// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ReportService } from "src/app/core/services/report.service";
import { APIDataSource } from "src/app/shared/helpers/api-data-source";


@Component({
  selector: 'patient-therapy-report',
  templateUrl: './patient-therapy-report.component.html',
  styleUrls: ['./patient-therapy-report.component.scss']
})
export class PatientTherapyReport implements OnInit {

  chartData: any[] = [];
  date: Date = new Date();
  dataSource: APIDataSource<any>;
  patient: any;
  displayedColumns: string[];

  constructor(
    public readonly reportService: ReportService,
    public readonly datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.reportService.getCallback().subscribe(res => {
      this.dataSource = res;
      this.chartData = res;
    });
    this.patient = this.reportService.getUserDetails();
    this.displayedColumns = this.reportService.getColumns();
  }
}