// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export function datesBetween(start: Date, end: Date) {
    var dates: Array<Date> = [];
    var curDate: Date = start;
    while (curDate <= end) {
        dates.push(curDate);
        curDate = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate()+1);
    }
    return dates;
}