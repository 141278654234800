// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from "chart.js";
import { Label } from 'ng2-charts';

@Component({
  selector: 'urinary-history-graph',
  templateUrl: './urinary-history-graph.component.html',
})
export class UrinaryHistoryGraph implements OnInit, OnChanges {
  public chartType: string = 'bar';
  public chartData: ChartDataSets[];
  public chartLabels: Label[];
  public chartOptions: (ChartOptions);
  public chartPlugins = [];

  @Input('data')
  data: any[];

  constructor(
    private readonly datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.createChart();
  }

  ngOnChanges(changes) {
    if (changes.data) {
      this.createChart();
    }
  }

  createChart() {
    var data = this.data.slice().sort((a, b) => {
      return new Date(a.date) > new Date(b.date) ? 1 : -1;
    });

    this.chartLabels = data.map(journalEntry => this.datePipe.transform(journalEntry.date, 'MMM d'));
    this.chartData = [
      {
        label: 'Restroom (Void))',
        data: data.map(journalEntry => journalEntry.restroom),
        borderColor: '#4d4d4d',
        backgroundColor: '#4d4d4d',
        hoverBackgroundColor: '#4d4d4d',
      },
      {
        label: 'Leaks',
        data: data.map(journalEntry => journalEntry.leaks),
        borderColor: '#989898',
        backgroundColor: '#989898',
        hoverBackgroundColor: '#989898',
      },
      {
        label: 'Urges',
        data: data.map(journalEntry => journalEntry.urge),
        borderColor: '#C4C4C4',
        backgroundColor: '#C4C4C4',
        hoverBackgroundColor: '#C4C4C4',
      },
    ];
    this.chartOptions = {
      responsive: true,
      legend: {
        display: true,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            ticks: {
              precision: 0,
              beginAtZero: true,
              autoSkip: true,
              maxTicksLimit: 20,
            },
            stacked: true,
          }
        ],
      },
    };

    this.chartPlugins = [{
      // ChartJS background color is transparent by default. This sets it to white for the pdf export
      beforeDraw: function(chartInstance) {
        var ctx = chartInstance.ctx;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, chartInstance.width, chartInstance.height);
      }
    }];
  }
}