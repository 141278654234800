// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { filter, take, switchMap, catchError, tap, finalize } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from '../core/services/auth.service';
import { PageLoadingService } from '../core/services/page-loading.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private readonly auth: AuthService, private readonly pageLoadingService: PageLoadingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes("refreshToken") && !request.url.includes("login")) {
            request = this.addAuthenticationToken(request);
        }

        request = this.addTimezone(request);

        this.pageLoadingService.start();

        return next.handle(request).pipe(
            catchError(error => {
                // We don't want to refresh token for some requests like login or refresh token itself
                // So we verify url and we throw an error if it's the case
                if (request.url.includes("refreshToken") || request.url.includes("login")) {
                    // We do another check to see if refresh token failed
                    // In this case we want to logout user and to redirect it to login page
                    if (request.url.includes("refreshToken")) {
                        this.auth.logout();
                    }

                    return throwError(error);
                }

                // If error status is different than 401 we want to skip refresh token
                // So we check that and throw the error if it's the case
                if (error.status !== 401) {
                    return throwError(error);
                }

                if (this.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => next.handle(this.addAuthenticationToken(request)))
                    );
                } else {
                    this.refreshTokenInProgress = true;

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    // Call auth.refreshAccessToken(this is an Observable that will be returned)
                    return this.auth.refresh().pipe(
                        switchMap((token: string) => {
                            // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                            // for the next time the token needs to be refreshed
                            this.refreshTokenInProgress = false;
                            this.refreshTokenSubject.next(token);

                            return next.handle(this.addAuthenticationToken(request));
                        }),
                        catchError((err: any) => {
                            this.refreshTokenInProgress = false;

                            this.auth.logout();
                            return throwError(error);
                        })
                    );
                }
            }),            
            finalize(() => this.pageLoadingService.end())
        );
    }

    addAuthenticationToken(request) {
        // Get access token from Local Storage
        const accessToken = this.auth.getAccessToken();

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getAccessToken()}`
            }
        });
    }

    addTimezone(request) {
        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        });
    }
}
