// Copyright 2023, Avation Medical. All rights reserved.

// 

// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification

// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.



import { Component, OnInit,Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';

import { MessageBoxDialogData } from '../../dialog-data.interface';





@Component({

  selector: 'app-message-box',

  templateUrl: './message-box.component.html',

  styleUrls: ['./message-box.component.scss'],

})

export class MessageBoxComponent implements OnInit {

  okCallback?: () => void;

  constructor(

    public dialogRef: MatDialogRef<MessageBoxComponent >,

    @Inject(MAT_DIALOG_DATA) public data: MessageBoxDialogData) {

      if (typeof data.okCallback === 'function') {

        this.okCallback = data.okCallback;

      }

    }



  ngOnInit(): void {}

  handleOkClick(): void {

    if (this.okCallback) {

      this.okCallback();

    }

    this.dialogRef.close(true);

  }

  

}