<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<ng-container *ngIf="control.invalid && (control.dirty || control.touched )">
  <ng-container *ngIf="control.errors.minlength">This field is too short</ng-container>
  <ng-container *ngIf="control.errors.maxlength">This field is too long</ng-container>
  <ng-container *ngIf="control.errors.pattern">Invalid value for this field</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.isValidDate">{{control.errors.isValidDate.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.isText">{{control.errors.isText.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.isOnlyWhitespace">{{control.errors.isOnlyWhitespace.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.isValidEmail">{{control.errors.isValidEmail.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.isValidPhone">{{control.errors.isValidPhone.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.isValidZipcode">{{control.errors.isValidZipcode.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.integer">{{control.errors.integer.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.isValidOTAVersion">{{control.errors.isValidOTAVersion.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.range">{{control.errors.range.message}}</ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.invalidDateRange">{{control.errors.invalidDateRange.message}}</ng-container>
  <ng-container *ngIf="control.errors.required">This field is required</ng-container>
  <ng-container *ngIf="control.errors.apiErrors">
    <ng-container *ngFor="let apiError of control.errors.apiErrors">{{ apiError }}</ng-container>
  </ng-container>
  <ng-container *ngIf="!control.errors.required && control.errors.greaterThanZero">{{control.errors.greaterThanZero.message}}</ng-container>
</ng-container>