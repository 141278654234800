// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export enum Navigation {
    Patients = 1,
    PatientInfo = 2,
    Therapy = 3,
    Journal = 4,
    Users = 5,
    Clinics = 6,
    Studies = 7,
    OTA = 8,
    Facilities=9,
    FacilitiesImport=10,
    Clinicians=11,
    CliniciansImport=12,
    Products=13,
    Home=14,
    Checkout=15,
    Ecommerce=16,
    Subscriptions=17,
    DiscountConfigs=18,
    SubscriptionConfigs=19,
    Acknowledgements = 20,
    Utilities = 21,
    Discounts=22,
    Login=23,
    AccountRequests=24
}

export namespace Navigation {
    export function toString(nav: Navigation): string {
        return Navigation[nav];
    }

    export function fromString(nav: string): Navigation {
        return (Navigation as any)[nav];
    }
}