<div class="dialog-container">
  <form [formGroup]="addressForm" class="form-outline-address">
    <fieldset class="model-form" [disabled]>
      <ng-template matStepmat-label>Address</ng-template>
      <h1 class="header">{{data?.addressType?.charAt(0).toUpperCase() + data?.addressType?.slice(1)}} Address</h1>
      <div class="address-form">
        <div class="model-form-row">
          <div class="form-box form-box-checkout">
            <mat-label>First Name</mat-label>
            <mat-form-field>
              <input matInput formControlName="first_name" type="text" placeholder="eg. John" />
              <mat-error><app-form-input-error
                  [control]="addressForm.get('first_name')"></app-form-input-error></mat-error>
            </mat-form-field>
          </div>
          <div class="form-box form-box-checkout">
            <mat-label>Last Name</mat-label>
            <mat-form-field>
              <input matInput formControlName="last_name" type="text" placeholder="eg. John" />
              <mat-error><app-form-input-error
                  [control]="addressForm.get('last_name')"></app-form-input-error></mat-error>
            </mat-form-field>
          </div>

        </div>
        <div class="model-form-row">
          <div class="form-box form-box-address lg-width">
            <mat-label>{{data?.addressType?.charAt(0).toUpperCase() + data?.addressType?.slice(1)}} Address 1</mat-label>
            <mat-form-field>
              <input matInput type="text" [value]="" placeholder="Street Address " formControlName="address_line_1" />
              <mat-error>
                <app-form-input-error [control]="addressForm.get('address_line_1')"></app-form-input-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-box form-box-address lg-width">
            <mat-label>{{data?.addressType?.charAt(0).toUpperCase() + data?.addressType?.slice(1)}} Address 2</mat-label>
            <mat-form-field>
              <input matInput formControlName="address_line_2" type="text"
                placeholder="Street Address line 2 (optional) " />
            </mat-form-field>
          </div>
        </div>
        <div class="model-form-row">
          <div class="form-box form-box-addressline">
            <mat-label>State</mat-label>
            <mat-form-field>
              <mat-select formControlName="administrative_district_level_1" placeholder="Select...">
                <mat-option *ngFor="let state of stateOptions" [value]="state.name">{{state.name}}</mat-option>
              </mat-select>
              <mat-error>
                <app-form-input-error
                  [control]="addressForm.get('administrative_district_level_1')"></app-form-input-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-box form-box-addressline">
            <mat-label>City</mat-label>
            <mat-form-field>
              <input matInput formControlName="locality" type="text" placeholder="eg. New York" />
              <mat-error>
                <app-form-input-error
                  [control]="addressForm.get('locality')"></app-form-input-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-box form-box-addressline">

            <mat-label>Zip</mat-label>
            <mat-form-field>
              <input matInput formControlName="postal_code" type="text" placeholder="eg. 94117" />
              <mat-error>
                <app-form-input-error [control]="addressForm.get('postal_code')"></app-form-input-error>
              </mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="btn-container">
          <button class="csr-button-secondary" (click)="cancel()" style="margin-top: 2em">
            Cancel
          </button>
          <button class="csr-button-primary" style="margin-top: 2em" (click)="recipientAddress()"
            [disabled]="addressForm.invalid">
            Add
          </button>
        </div>
      </div>

    </fieldset>
  </form>
</div>