<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="report" id="report">
    <div class="report-header">
        <div class="logo">
            <img class="logo-icon" src="../../../assets/icons/ic_logo.png">
        </div>
        <div class="center">
            <div class="title">Report for {{patient.patientId || 'N/A'}}</div>
            <div class="user-data">
                <div><b>Initial Screening: </b>{{patient.enrollmentDate | date: 'MM/dd/yyyy':'UTC'}}</div>
                <div><b>Clinician: </b>{{patient.clinicianName}}</div>
            </div>
        </div>
    </div>

    <div class="body">
        <h1 class="title">Individual Journal Entries Sessions</h1>
    </div>
    <div class="chart">
        <urinary-history-graph [data]="chartData"></urinary-history-graph>
        <drinks-history-graph [data]="chartData"></drinks-history-graph>
    </div>

    <table mat-table [dataSource]="dataSource"fixedLayout="true">

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">{{row.date | date: 'MMM d, yyyy':'UTC'}}</td>
        </ng-container>

        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disabled="true">Time</th>
            <td mat-cell *matCellDef="let row">-</td>
        </ng-container>

        <ng-container matColumnDef="restroom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Voids</th>
            <td mat-cell *matCellDef="let row">{{row.restroom}}</td>
        </ng-container>

        <ng-container matColumnDef="leaks">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Leaks</th>
            <td mat-cell *matCellDef="let row">{{row.leaks}}</td>
        </ng-container>

        <ng-container matColumnDef="urge">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Urge</th>
            <td mat-cell *matCellDef="let row">{{row.urge}}</td>
        </ng-container>

        <ng-container matColumnDef="interruptedSleep">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Interrupted Sleep</th>
            <td mat-cell *matCellDef="let row">{{row.interruptedSleep}}</td>
        </ng-container>

        <ng-container matColumnDef="changes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Change Pad/Clothing</th>
            <td mat-cell *matCellDef="let row">{{row.changes}}</td>
        </ng-container>

        <ng-container matColumnDef="drinksWaterOther">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Water and Other</th>
            <td mat-cell *matCellDef="let row">{{row.drinksWaterOther | fluid: selectedFluidUnit}}</td>
        </ng-container>

        <ng-container matColumnDef="drinksCaffeinated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Caffeinated</th>
            <td mat-cell *matCellDef="let row">{{row.drinksCaffeinated | fluid: selectedFluidUnit}}</td>
        </ng-container>

        <ng-container matColumnDef="drinksAlcohol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Alcohol</th>
            <td mat-cell *matCellDef="let row">{{row.drinksAlcohol | fluid: selectedFluidUnit}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length" style="text-align: center;">No data</td>
        </tr>
    </table>
</div>

<button id="exportButton" class="export-button" mat-raised-button (click)="reportService.exportReport()">Export</button>