// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Pipe, PipeTransform } from '@angular/core';
import { DeviceMode } from '../enums/device-mode';

@Pipe({name: 'deviceMode'})
export class DeviceModePipe implements PipeTransform {
    transform(value: any): any {
        let deviceMode = value as DeviceMode;

        switch(deviceMode) {
            case DeviceMode.Active:
                return 'Active';
            case DeviceMode.NoStim:
                return 'No Stim';
            case DeviceMode.LowStim:
                return 'Low Stim';
        }
        
        return 'Invalid';
    }
}