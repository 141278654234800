import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit {
  title: string;
  footerTitle: string;
  secondaryBtnName: string;
  primaryBtnName: string;
  onClick: () => void;
  missingFields:any[]=[]
  // secondaryBtnFunction: () => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.footerTitle = this.data.footerTitle;
    this.secondaryBtnName = this.data.secondaryBtnName;
    this.primaryBtnName = this.data.primaryBtnName;
    this.onClick = this.data.primaryBtnFunction;
    this.missingFields=this.data.missingFields
    // this.secondaryBtnFunction = this.data.secondaryBtnFunction;
  }

}
