// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export enum FluidUnit {
    milliliters = "ml",
    ounces = "oz",
    cups = "cups"
}

export function fluidUnitOptions() {
    return Object.keys(FluidUnit)
      .filter(key => FluidUnit[key] != '')
      .map(key => ({ key: key, value: FluidUnit[key] }));
  }