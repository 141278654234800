import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class AcknowledgementsService {

  constructor(private appSettingsService: AppSettingsService, private httpClient: HttpClient) {     
  }

  getAcknowledgementsHtml() {
    return this.appSettingsService.appSettings$.pipe(switchMap(appSettings => this.httpClient.get<{html: string}>(`${appSettings.apiBaseUrl}/v1.0/acknowledgements`)));
  }
}
