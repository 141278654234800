import { RightResponsibilityService } from 'src/app/core/services/right-responsibility.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AcknowledgementsService } from 'src/app/core/services/acknowledgements.service';
import { LocationService } from 'src/app/core/services/location.service';
import { Navigation } from 'src/app/shared/enums/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-right-responsibility',
  templateUrl: './right-responsibility.component.html',
  styleUrls: ['./right-responsibility.component.scss']
})
export class RightResponsibilityComponent implements OnInit {
  htmlLoc$: Observable<SafeResourceUrl>;

  constructor(
    private rightResponsibilityService: RightResponsibilityService, 
    private readonly sanitizer: DomSanitizer, 
    private locationService: LocationService
    ) { }

  ngOnInit(): void {
    this.locationService.setTitle('Rights And Responsibility');
    this.htmlLoc$ = this.rightResponsibilityService.getRightResponsibilityHtml().pipe(
      map(response => this.sanitizer.bypassSecurityTrustHtml(response.html))
    );
  }

}
