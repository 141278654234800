// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError, timer } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../http/user/user.service';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { LocationService } from './location.service';
import { SubjectService } from '../http/subject/subject.service';
import { AccountLoginModel } from 'src/app/shared/models/login';

export class AuthResponse {
  authenticationResult: AuthenticationResult;
  challengeName: any;
  httpStatusCode: number;
  session?: string;
}

export class AuthenticationResult {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  tokenType: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements CanActivate {
  session: string;

  private authenticatedSub = new BehaviorSubject(false);
  public get authenticated$() {
    return this.authenticatedSub.asObservable();
  }

  private accessTokenSub = new BehaviorSubject<string>(
    localStorage.getItem('accessToken')
  );
  public get accessToken$() {
    return this.accessTokenSub.asObservable();
  }

  private refreshTokenSub = new BehaviorSubject<string>(
    localStorage.getItem('refreshToken')
  );
  public get refreshToken$() {
    return this.refreshTokenSub.asObservable();
  }

  private idTokenSub = new BehaviorSubject<string>(
    localStorage.getItem('idToken')
  );
  public get idToken$() {
    return this.idTokenSub.asObservable();
  }

  private authCodeLockSub = new BehaviorSubject<boolean>(false);
  public get authCodeLock$() {
    return this.authCodeLockSub.asObservable();
  }

  public get username$(): Observable<string> {
    return this.accessToken$.pipe(
      map((token) => {
        if (!token) {
          return null;
        }
        let payload = atob(token.split('.')[1]);
        let claims = JSON.parse(payload);
        if (claims) {
          return claims['username'];
        }
      })
    );
  }

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly userService: UserService,
    private readonly appSettingsService: AppSettingsService,
    private readonly locationService: LocationService,
    private readonly subjectService:SubjectService
  ) {}

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  public async login(model: AccountLoginModel) {
    const appSettings = await this.appSettingsService.appSettings$.pipe(take(1)).toPromise();

    return this.http
      .post(`${appSettings.apiBaseUrl}/v1/account/login`, model)
      .toPromise()
      .then(async (response: AuthResponse) => {
        if (response) {
          if (response.authenticationResult) {
            // Store the tokens.
            localStorage.setItem('accessToken', response.authenticationResult.accessToken);
            localStorage.setItem('refreshToken', response.authenticationResult.refreshToken);
            localStorage.setItem('idToken', response.authenticationResult.idToken);

            this.accessTokenSub.next(response.authenticationResult.accessToken);
            this.refreshTokenSub.next(response.authenticationResult.refreshToken);
            this.idTokenSub.next(response.authenticationResult.idToken);

            if (this.hasRole('Patient')) {
              let loginPermission = await this.userService.getMyUser().toPromise().then(detail => detail.portalLogin);
              if (!loginPermission) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('idToken');
                return Promise.reject('Patient portal access disabled for this study');
              }
              this.userService.getMyUser().toPromise().then(detail => this.locationService.setPatientUserId(detail.id));
            }

            let acceptedEULA = await this.userService.getMyUser().toPromise().then(detail => detail.acceptedEULA);
            this.router.navigate([acceptedEULA ? '/' : '/login/tos']);
          } else if (response.challengeName && response.challengeName.value) {
            if (response.challengeName.value == 'NEW_PASSWORD_REQUIRED') {
              // Get the session and navigate
              if (response.session) {
                this.router.navigate([
                  '/login/createPassword/' + response.session,
                ]);
              }
            }
          }
        }
        return response;
      });
  }

  public refresh(): Observable<string> {
    const params = {
      refreshToken: localStorage.getItem('refreshToken'),
    };
    if (!params.refreshToken) return throwError('invlaid refreshToken');

    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.post(`${appSettings.apiBaseUrl}/v1/account/refreshToken`, params)
      ),
      switchMap((response: AuthResponse) => {
        if (response && response.authenticationResult) {
          // Store the refreshed tokens.
          localStorage.setItem('accessToken', response.authenticationResult.accessToken);
          localStorage.setItem('idToken', response.authenticationResult.idToken);
          return of(response.authenticationResult.accessToken);
        }
        return of(null);
      })
    );
  }

  public acceptedEULA() {
    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.post(`${appSettings.apiBaseUrl}/v1/account/AcceptedEULA`, {})
      )
    );
  }

  public getEULA() {
    let roles = this.getRoles();
    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) => {
        let endpoint = roles.includes('Clinician') ? 'clinician' : 'patient';
        return this.http.get<{ html: string }>(
          `${appSettings.apiBaseUrl}/v1.0/eula/${endpoint}`
        );
      })
    );
  }

  public logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('idToken');
    this.zone.run(() => {
      this.router.navigate(['login']);
    });
  }

  public getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  public isAuthenticated() {
    try {
      if (localStorage.getItem('accessToken') != null) {
        let groups = this.getRoles();
        if (groups) {
          let allowedRoles = [
            'Admin',
            'Clinician',
            'Engineer',
            'CustomerCare',
            'Operations',
            'Monitor',
            'Patient',
          ];
          for (let i = 0; i < groups.length; ++i) {
            for (let j = 0; j < allowedRoles.length; ++j) {
              if (groups[i] === allowedRoles[j]) {
                return true;
              }
            }
          }
        }
      }
    } catch {}

    return false;
  }

  public getName() {
    let token = this.getAccessToken();
    let payload = atob(token.split('.')[1]);
    let claims = JSON.parse(payload);
    return claims['username'];
  }

  public getRoles() {
    try {
      // parse token
      let token = this.getAccessToken();
      let payload = atob(token.split('.')[1]);
      let claims = JSON.parse(payload);
      let groups: string[] = claims['cognito:groups'];
      if (groups) {
        return groups;
      }
      return [];
    } catch (ex) {
      return [];
    }
  }

  public canSeeDetails() {
    return this.hasRole([
      'Admin', 'CustomerCare', 'Monitor', 'Engineer'
    ])
  }

  public hasRole(roles: string|string[]) {
    if (!Array.isArray(roles)) {
      roles = [roles];
    }
    let myRoles = this.getRoles();

    for (let role of roles) {
      if (myRoles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  public isAdmin() {
    for (let role of this.getRoles()) {
      if (role == 'Admin') {
        return true;
      }
    }

    return false;
  }

  canOrderForPatient() {
    return this.hasRole(['Admin', 'CustomerCare']);
}

  public async hasPrescription(id:any){
    if (this.canOrderForPatient()) {
      return  this.subjectService.querySubjectsReport(null, id).toPromise().then((res:any)=>{
        if(res.records[0].prescriptionDate){
          return true
        }
        return false
      })
    }else if(this.hasRole('Patient')){
    return this.userService.getMyUser().toPromise().then(detail=>
      {
     return  this.subjectService.querySubjectsReport(null,detail.id).toPromise().then((res:any)=>{
        if(res.records[0].prescriptionDate){
          return true
        }
        return false
      })
    })
  }
  }

  public lockAccessCode() {
    this.authCodeLockSub.next(true);
    timer(60000).subscribe(_ => {
      this.authCodeLockSub.next(false);
    })
  }
}
