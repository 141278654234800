// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormInputErrorComponent } from './components/form-input-error/form-input-error.component';
import { DeleteConfirmDialogComponent } from './components/delete-confirm-dialog/delete-confirm-dialog.component';
import { BatteryLevelFormatPipe } from './pipes/battery-level-format.pipe';
import { EpochDatePipe } from './pipes/epoch-date.pipe';
import { TherapyCompliancePipe } from './pipes/therapy-compliance.pipe';
import { FootPipe } from './pipes/foot.pipe';
import { PhoneNumberInput } from './components/phone-number-input/phone-number-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { IdleWarningDialogComponent } from './components/idle-warning-dialog/idle-warning-dialog.component';
import { DeviceModePipe } from './pipes/device-mode.pipe';
import { DateTimeOffsetPipe } from './pipes/date-time-offset.pipe';
import { ProductPipe } from './pipes/product.pipe';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { StatusPopupComponent } from './components/status-popup/status-popup.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { ResetEulaDialogComponent } from './components/reset-eula-dialog/reset-eula-dialog.component';
import { DeletePrescriptionModalComponent } from './components/delete-prescription-modal/delete-prescription-modal.component';
import { UploadPrescriptionModalComponent } from './components/upload-prescription-modal/upload-prescription-modal.component';
import { ImageDisplayComponent } from './components/image-display/image-display.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { AddConfirmDialogComponent } from './components/add-confirm-dialog/add-confirm-dialog.component';
import { TherapySchedulePipe } from './pipes/therapy-schedule.pipe';
import { CentsToDollarPipe } from './pipes/cents-to-dollar.pipe';
import { SumPricePipe } from './pipes/sum-price.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import { IntegerDirective } from './directives/integer.directive';
import { InstallmentPipe } from './pipes/installment.pipe';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { FluidPipe } from './pipes/fluid-value.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SendNotificationComponent } from './components/navbar/send-notification/send-notification.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DigitBoxInput } from './components/digit-box-input/digit-box-input.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TherapyStatusPipe } from './pipes/therapy-status.pipe';


@NgModule({
  declarations: [
    EpochDatePipe, 
    TherapyCompliancePipe,
    TherapySchedulePipe,
    BatteryLevelFormatPipe,
    DateTimeOffsetPipe,
    FootPipe,
    FormInputErrorComponent,
    AddConfirmDialogComponent,
    DeleteConfirmDialogComponent,
    PhoneNumberInput,
    PhoneNumberPipe,
    IdleWarningDialogComponent,
    DeviceModePipe,
    ProductPipe,
    MessageBoxComponent,
    StatusPopupComponent,
    EnumToArrayPipe,
    ResetEulaDialogComponent,
    DeletePrescriptionModalComponent,
    UploadPrescriptionModalComponent,
    ImageDisplayComponent,
    ReceiptComponent,
    CentsToDollarPipe,
    SumPricePipe,
    IntegerDirective,
    InstallmentPipe,
    AddAddressComponent,
    FluidPipe,
    FooterComponent,
    NavbarComponent,
    SendNotificationComponent,
    DigitBoxInput,
    TherapyStatusPipe
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    MatMenuModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  exports: [
    EpochDatePipe, 
    TherapyCompliancePipe,
    TherapySchedulePipe,
    BatteryLevelFormatPipe,
    DateTimeOffsetPipe,
    FootPipe,
    FormInputErrorComponent,
    PhoneNumberInput,
    PhoneNumberPipe,
    DeviceModePipe,
    ProductPipe,
    EnumToArrayPipe,
    ImageDisplayComponent,
    ReceiptComponent,
    CentsToDollarPipe,
    SumPricePipe,
    MatTooltipModule,
    IntegerDirective,
    InstallmentPipe,
    FluidPipe,
    FooterComponent,
    NavbarComponent,
    SendNotificationComponent,
    DigitBoxInput,
    TherapyStatusPipe
  ]
})
export class SharedModule { }
