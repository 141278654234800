// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AppSettings } from 'src/app/app-settings/app-settings';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { APIQuery } from 'src/app/shared/models/api-query.interface';
import { PagedResult } from 'src/app/shared/models/paged-result.interface';
import { SubjectReport } from 'src/app/shared/models/subject';
import { UserPrescription } from 'src/app/shared/models/user-prescription';

@Injectable({
  providedIn: 'root'
})
export class UserPrescriptionService {
  appSettings$: Observable<AppSettings>;

  constructor(private readonly appSettingsService: AppSettingsService, private readonly http: HttpClient) { 
    this.appSettings$ = appSettingsService.appSettings$;
  }

  read(id: string): Observable<UserPrescription> {
    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.get<UserPrescription>(`${appSettings.apiBaseUrl}/v1/userprescriptions/${id}`))
    );
  }

  query(apiQuery?: APIQuery, guid?: string, userId?: number, isSoftDeleted?: boolean): Observable<PagedResult<UserPrescription>> 
  {
    let params = { };

    if(guid) 
    {
      params["guid"] = guid.toString();
    }

    if(userId) 
    {
      params["userId"] = userId.toString();
    }

    if(apiQuery) 
    {
      params = Object.assign({}, apiQuery, params);
    }

    if(typeof isSoftDeleted === 'boolean') 
    {
      params["isSoftDeleted"] = isSoftDeleted ? 'true' : 'false';
    }
    
    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<UserPrescription>>(`${appSettings.apiBaseUrl}/v1/userprescriptions`, {params}))
    );
  }

  create(model: Partial<UserPrescription>, document: File, removeExisting?: boolean): Observable<number> {
    let form = new FormData();

    if(model.fileName) {
      form.append('fileName', model.fileName);
    }

    if(model.userId) {
      form.append('userId', model.userId.toString());
    }

    if (!!removeExisting) {
      form.append('removeExisting', removeExisting.toString());
    }

    form.append('document', document);

    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.post<number>(`${appSettings.apiBaseUrl}/v1/userprescriptions`, form))
    )
  }

  getPdfTemplate(userId?: number): Observable<ArrayBuffer> {
    let params = new HttpParams();

    if(userId) {
      params = params.append('userId', userId.toString());
    }

    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/userprescriptions/pdf`, {params, responseType: 'arraybuffer'}))
    );
  }

  getPdf(userId?: number): Observable<ArrayBuffer> {
    let params = new HttpParams();

    if(userId) {
      params = params.append('userId', userId.toString());
    }

    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/userprescriptions/uploadedpdf`, {params, responseType: 'arraybuffer'}))
    );
  }

  getPdfById(prescriptionId: number, userId?: number): Observable<ArrayBuffer> {
    let params = new HttpParams();

    params = params.append('prescriptionId', prescriptionId.toString());

    if(userId) {
      params = params.append('userId', userId.toString());
    }

    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1/userprescriptions/getpdfbyid`, {params, responseType: 'arraybuffer'}))
    );
  }

  delete(userId?: number): Observable<number> {
    let params = new HttpParams();

    if(userId) {
      params = params.append('userId', userId.toString());
    }

    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.delete<number>(`${appSettings.apiBaseUrl}/v1/userprescriptions`, {params}))
    );
  }

  async downloadPdf(patient: SubjectReport, template: boolean = false) {
    let buffer: ArrayBuffer;
    if (template) {
      buffer = await this.getPdfTemplate(patient.userId).pipe(take(1)).toPromise();
    }
    else {
      buffer = await this.getPdf(patient.userId).pipe(take(1)).toPromise();
    }

    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api         

    var pdfBlob = new Blob([buffer], { type: "application/pdf" });

    const data = window.URL.createObjectURL(pdfBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = `RX-${patient.patientId}${template ? '-Template' : ''}.pdf`;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  async downloadPdfById(prescriptionId: number, patient: SubjectReport) {
    let buffer = await this.getPdfById(prescriptionId, patient.userId).pipe(take(1)).toPromise() as ArrayBuffer;

    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api         

    var pdfBlob = new Blob([buffer], { type: "application/pdf" });

    const data = window.URL.createObjectURL(pdfBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = `RX-${patient.patientId}.pdf`;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  //send docusign envelop for e-sign
  sendRxEnvelope(payload): Observable<any> {
    return this.appSettings$.pipe(
      switchMap(appSettings => this.http.post<any>(`${appSettings.apiBaseUrl}/v1/docusign/prescription/email/sent`, payload))
    )
  }
}
