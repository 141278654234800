import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AcknowledgementsService } from 'src/app/core/services/acknowledgements.service';
import { LocationService } from 'src/app/core/services/location.service';
import { Navigation } from 'src/app/shared/enums/navigation';

@Component({
  selector: 'app-acknowledgements',
  templateUrl: './acknowledgements.component.html',
  styleUrls: ['./acknowledgements.component.scss']
})
export class AcknowledgementsComponent implements OnInit, AfterViewInit, OnDestroy {
  htmlLoc$: Observable<SafeResourceUrl>;

  constructor(private acknowledgementsService: AcknowledgementsService, private readonly sanitizer: DomSanitizer, private locationService: LocationService) { }

  ngOnInit(): void {
    this.locationService.setLocation(Navigation.Acknowledgements);
    this.locationService.setTitle('Acknowledgements');
    this.htmlLoc$ = this.acknowledgementsService.getAcknowledgementsHtml().pipe(
      map(response => this.sanitizer.bypassSecurityTrustHtml(response.html))
    );
  }

  ngAfterViewInit(): void {
    let baseElements = document.getElementsByTagName("base");

    if(baseElements.length > 0) {
      baseElements[0].removeAttribute("href");
    }
  }

  ngOnDestroy(): void {
    let baseElements = document.getElementsByTagName("base");

    if(baseElements.length > 0) {
      baseElements[0].setAttribute("href", "/")
    }
  }
}
