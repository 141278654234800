// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { skipWhile, switchMap, tap } from 'rxjs/operators';
import { ProductService } from 'src/app/core/http/product/product.service';
import { UserService } from 'src/app/core/http/user/user.service';
import { LocationService } from 'src/app/core/services/location.service';
import { AuthService } from './../../../core/services/auth.service';


@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, OnChanges {
  @Input() receiptId
  receipt$: Observable<any>;
  userDetails$: Observable<any>
  subscription$: Observable<any>
  invoices$: Observable<any>
  dataSource: Array<object> = []
  subscriptionId:string



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly productService: ProductService,
    private readonly userService: UserService,
    private readonly navigationService: LocationService,
    private dialogRef: MatDialogRef<ReceiptComponent>,
    private readonly authService: AuthService,
  ) { }

  userDetailsLoadedSubject = new BehaviorSubject<boolean>(false);
  get everythingLoaded$() {
    return combineLatest([
      this.userDetailsLoadedSubject
    ]).pipe(skipWhile(([x]) => !x));
  }

  ngOnInit(): void {
    this.loadUser()
    this.getSubscription()
    this.getInvoices()


  }

  isCustomerAdmin() {
      return this.authService.hasRole(['Admin', 'CustomerCare']);
  }


  loadUser() {
    this.userDetails$ = this.userService
      .getMyUser()
      .pipe(
        switchMap((res) => {
          this.navigationService.setPatientUserId(res.id)
          this.userDetailsLoadedSubject.next(true)
          return of(res.id)
        }));
  }

  download(subscriptionId,invoiceId){
    this.productService.downloadInvoicePdf(subscriptionId,invoiceId,this.navigationService.patientUserId,this.data.orderId,'subscription',this.data.orderId)
  }

  getSubscription() {
    this.subscription$ = this.userDetails$.pipe(
      switchMap(() => {
        if (this.isCustomerAdmin()) {
          return this.productService.getSubscriptions(null, this.data.orderId, null, null)
        }else{
          return this.productService.getSubscriptions(null, this.data.orderId, null, this.navigationService.patientUserId)
        }
      }),
      tap((pageResult) => {
        return pageResult.records[0]
      })
    )
  }

  getInvoices() {
    this.invoices$ = this.subscription$.pipe(
      switchMap((subscription) => {
        this.subscriptionId=subscription.records[0].subscriptionId
        return this.productService.getSubscription(subscription.records[0].subscriptionId, this.navigationService.patientUserId)

      }),
      tap((response) => {
        response.subscription.invoice_ids.map((invoiceId) => {
          this.productService.getInvoice(invoiceId, this.navigationService.patientUserId).toPromise().then((res: any) => {
            this.dataSource = [...this.dataSource, res].sort((a: any, b: any) => { return a.created_at > b.created_at ? 1 : -1 })
          })

        })
        return this.dataSource
      })
    )
  }
  cancel(){
    this.dialogRef.close()
  }
  ngOnChanges(changes: SimpleChanges): void {
  }


}
