// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'therapyCompliance'})
export class TherapyCompliancePipe extends PercentPipe implements PipeTransform {
    transform(value: any, digitsInfo?: string, locale?: string): any {
        if (value === null) {
            return 'N/A';
        }
        return super.transform(value, digitsInfo, locale);
    }
}