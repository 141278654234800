// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export function states(){
    const states= [
      {
        "name": "Alaska",
        "cities": ["Anchorage", "Fairbanks", "Juneau", "Sitka"]
      },
      {
        "name": "Alabama",
        "cities": ["Birmingham", "Montgomery", "Mobile", "Huntsville"]
      },
      {
        "name": "Arkansas",
        "cities": ["Little Rock", "Fort Smith", "Fayetteville", "Springdale"]
      },
      {
        "name": "American Samoa",
        "cities": ["Pago Pago"]
      },
      {
        "name": "Arizona",
        "cities": ["Phoenix", "Tucson", "Mesa", "Scottsdale"]
      },
      {
        "name": "California",
        "cities": ["Los Angeles", "San Francisco", "San Diego", "Sacramento"]
      },
      {
        "name": "Colorado",
        "cities": ["Denver", "Colorado Springs", "Aurora", "Fort Collins"]
      },
      {
        "name": "Connecticut",
        "cities": ["Bridgeport", "New Haven", "Hartford", "Stamford"]
      },
      {
        "name": "District of Columbia",
        "cities": ["Washington DC"]
      },
      {
        "name": "Delaware",
        "cities": ["Wilmington", "Dover", "Newark", "Middletown"]
      },
      {
        "name": "Florida",
        "cities": ["Miami", "Orlando", "Tampa", "Jacksonville"]
      },
      {
        "name": "Georgia",
        "cities": ["Atlanta", "Savannah", "Augusta", "Macon"]
      },
      {
        "name": "Guam",
        "cities": ["Hagåtña", "Tamuning", "Dededo", "Yigo"]
      },
      {
        "name": "Hawaii",
        "cities": ["Honolulu", "Hilo", "Kailua", "Kapolei"]
      },
      {
        "name": "Iowa",
        "cities": ["Des Moines", "Cedar Rapids", "Davenport", "Sioux City"]
      },
      {
        "name": "Idaho",
        "cities": ["Boise", "Meridian", "Nampa", "Idaho Falls"]
      },
      {
        "name": "Illinois",
        "cities": ["Chicago", "Springfield", "Peoria", "Aurora"]
      },
      {
        "name": "Indiana",
        "cities": ["Indianapolis", "Fort Wayne", "Evansville", "South Bend"]
      },
      {
        "name": "Kansas",
        "cities": ["Wichita", "Overland Park", "Kansas City", "Topeka"]
      },
      {
        "name": "Kentucky",
        "cities": ["Louisville", "Lexington", "Bowling Green", "Owensboro"]
      },
      {
        "name": "Louisiana",
        "cities": ["New Orleans", "Baton Rouge", "Shreveport", "Lafayette"]
      },
      {
        "name": "Massachusetts",
        "cities": ["Boston", "Worcester", "Springfield", "Cambridge"]
      },
      {
        "name": "Maryland",
        "cities": ["Baltimore", "Frederick", "Rockville", "Gaithersburg"]
      },
      {
        "name": "Maine",
        "cities": ["Portland", "Lewiston", "Bangor", "South Portland"]
      },
      {
        "name": "Michigan",
        "cities": ["Detroit", "Grand Rapids", "Lansing", "Ann Arbor"]
      },
      {
        "name": "Minnesota",
        "cities": ["Minneapolis", "Saint Paul", "Rochester", "Duluth"]
      },
      {
        "name": "Missouri",
        "cities": ["Kansas City", "St. Louis", "Springfield", "Columbia"]
      },
      {
        "name": "Mississippi",
        "cities": ["Jackson", "Gulfport", "Southaven", "Hattiesburg"]
      },
      {
        "name": "Montana",
        "cities": ["Billings", "Missoula", "Great Falls", "Bozeman"]
      },
      {
        "name": "North Carolina",
        "cities": ["Charlotte", "Raleigh", "Greensboro", "Durham"]
      },
      {
        "name": "North Dakota",
        "cities": ["Fargo", "Bismarck", "Grand Forks", "Minot"]
      },
      {
        "name": "Nebraska",
        "cities": ["Omaha", "Lincoln", "Bellevue", "Grand Island"]
      },
      {
        "name": "New Hampshire",
        "cities": ["Manchester", "Nashua", "Concord", "Derry"]
      },
      {
        "name": "New Jersey",
        "cities": ["Newark", "Jersey City", "Trenton", "Atlantic City"]
      },
      {
        "name": "New Mexico",
        "cities": ["Albuquerque", "Las Cruces", "Rio Rancho", "Santa Fe"]
      },
      {
        "name": "Nevada",
        "cities": ["Las Vegas", "Henderson", "Reno", "North Las Vegas"]
      },
      {
        "name": "New York",
        "cities": ["New York City", "Buffalo", "Albany", "Rochester"]
      },
      {
        "name": "Ohio",
        "cities": ["Columbus", "Cleveland", "Cincinnati", "Dayton"]
      },
      {
        "name": "Oklahoma",
        "cities": ["Oklahoma City", "Tulsa", "Norman", "Broken Arrow"]
      },
      {
        "name": "Oregon",
        "cities": ["Portland", "Salem", "Eugene", "Gresham"]
      },
      {
        "name": "Pennsylvania",
        "cities": ["Philadelphia", "Pittsburgh", "Harrisburg", "Allentown"]
      },
      {
        "name": "Puerto Rico",
        "cities": ["San Juan", "Bayamón", "Carolina", "Ponce"]
      },
      {
        "name": "Rhode Island",
        "cities": ["Providence", "Warwick", "Cranston", "Pawtucket"]
      },
      {
        "name": "South Carolina",
        "cities": ["Columbia", "Charleston", "North Charleston", "Mount Pleasant"]
      },
      {
        "name": "South Dakota",
        "cities": ["Sioux Falls", "Rapid City", "Aberdeen", "Brookings"]
      },
      {
        "name": "Tennessee",
        "cities": ["Nashville", "Memphis", "Knoxville", "Chattanooga"]
    },
    {
      "name": "Texas",
      "cities": ["Houston", "Dallas", "Austin", "San Antonio"]
    },
    {
      "name": "Utah",
      "cities": ["Salt Lake City", "West Valley City", "Provo", "Orem"]
    },
    {
      "name": "Virginia",
      "cities": ["Virginia Beach", "Norfolk", "Chesapeake", "Richmond"]
    },
    {
      "name": "Virgin Islands",
      "cities": ["Charlotte Amalie", "Saint Thomas", "Saint John", "Saint Croix"]
    },
    {
      "name": "Vermont",
      "cities": ["Burlington", "South Burlington", "Rutland", "Montpelier"]
    },
    {
      "name": "Washington",
      "cities": ["Seattle", "Tacoma", "Spokane", "Vancouver"]
    },
    {
      "name": "Wisconsin",
      "cities": ["Milwaukee", "Madison", "Green Bay", "Kenosha"]
    },
    {
      "name": "West Virginia",
      "cities": ["Charleston", "Huntington", "Parkersburg", "Morgantown"]
    },
    {
      "name": "Wyoming",
      "cities": ["Cheyenne", "Casper", "Laramie", "Gillette"]
    }
  ]
  
  return states
}
