import { AuthService } from 'src/app/core/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { version } from 'package.json';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { Navigation } from 'src/app/shared/enums/navigation';
import { LocationService } from 'src/app/core/services/location.service';

@Component({
  selector: 'vivally-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() showLinks: boolean = true;
  appVersion: string = '';
  location$: Observable<Navigation>;
  pageType= Navigation.Login;
  copyrightYear: number = new Date().getFullYear();

  apiVersion$: Observable<string>;
  showAccount: boolean = false;

  constructor(private http: HttpClient, private appSettingsService: AppSettingsService, private readonly authService: AuthService, private readonly navigationService: LocationService,) { }

  ngOnInit(): void {
    this.location$ = this.navigationService.getLocation();
    this.appVersion = version;

    this.apiVersion$ = this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/version`, {responseType: 'text'})),
      map(version => version.split('.').slice(0, 3).join('.')),
      catchError(err => of(''))
    );
    this.showAccountDeletion();
  }

  showAccountDeletion() {
    this.location$.subscribe((loc) => {
      this.showAccount = loc === this.pageType;
    });
  }

  public isPatient() {
    if (this.authService.getRoles().includes('Patient')) {
      return true;
    }
    return false;
  }
}
