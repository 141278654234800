// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumber } from './classes/phone-number';

export function rangeValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    return (min <= control.value && control.value <= max)
      ? null
      : { range: { message: 'Value must be between ' + min + ' and ' + max } };
  };
}

export function percentValidator(): ValidatorFn {
  return rangeValidator(0, 100);
}

export function integerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    return /^\d+$/.test(String(control.value))
      ? null
      : { integer: { message: 'Value must only contain digits' } };
  };
}

export function textValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }
    
    return /^[A-Za-zŽžÀ-ÖØ-Ýà-öø-ÿ]+$/.test(String(control.value))
      ? null
      : { isText: { message: 'Value must only contain valid characters' } };
  };
}

export function textValidatorOptional(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }
    
    return /^[a-zA-Z\s]+$/.test(String(control.value))
      ? null
      : { isText: { message: 'Value must only contain characters' } };
  };
}

export function nameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }
    
    return /^[A-Za-zŽžÀ-ÖØ-Ýà-öø-ÿ \'\(\)\-\,\.]+$/.test(String(control.value))
      ? null
      : { isText: { message: 'Value must only contain valid characters' } };
  };
}

export function nonWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    return (control.value || '').trim().length
      ? null
      : { isOnlyWhitespace: { message: 'Value must contain valid characters' } };
  };
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
      String(control.value)
    )
      ? null
      : { isValidEmail: { message: 'Enter valid Email' } };
  };
}

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    let val = typeof control.value == 'string' ? PhoneNumber.fromString(control.value) : control.value;
    return val.valid
      ? null
      : { isValidPhone: { message: 'Enter valid Phone Number' } };
  };
}

export function zipValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    return /^(?:\d{5}(?:-\d{4})?|)$/.test(String(control.value))
      ? null
      : { isValidZipcode: { message: 'Enter valid Zip Code' } };
  };
}

export function otaVersionValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return /^(\d{1,2}\.\d{1,2}\.\d{1,2})/.test(
      String(control.value)
    )
      ? null
      : { isValidOTAVersion: { message: 'Must be in format x.x.x' } };
  };
}

export function zeroValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Check if the control value is not greater than 0, return an error if true
    if (Number(control.value) <= 0) {
      return { greaterThanZero: { message: 'Value must be greater than 0' } };
    }
    // Return null (no error) if the value is valid (not null and greater than 0)
    return null;
  };
}

export function confirmPassword(password_field: string, confirm_field: string): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get(password_field).value;
    let confirmPass = group.get(confirm_field).value;
    return pass === confirmPass ? null : { passwordsNotSame: { message: 'Passwords do not match' } };
  }
}

export function dateRangeValidator(formGroup: FormGroup) {
  const fromDate = formGroup.get('fromDate').value;
  const toDate = formGroup.get('toDate').value;

  if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
    formGroup.get('fromDate').setErrors({ 'invalidDateRange': {message:'From Date must be less than or equal to To Date'} });
  } else {
    formGroup.get('fromDate').setErrors(null);
  }
}
