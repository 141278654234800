<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="dialog">
    <h2 mat-dialog-title>Idle Timeout</h2>
    <mat-dialog-content>
        <p>You have been idle for too long</p>
        <p>You will be logged out in {{data.countdown}} seconds...</p>
    </mat-dialog-content>
</div>