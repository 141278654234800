// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusPopupDialogData } from '../../dialog-data.interface';


@Component({
  selector: 'app-add-record',
  templateUrl: './status-popup.component.html',
  styleUrls: ['./status-popup.component.scss'],
})
export class StatusPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<StatusPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StatusPopupDialogData
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.close();
    },2500);
  }
  get textColor() {
    return this.data.color ? 'red' : '#039855';
  }
}
