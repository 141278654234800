// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Navigation } from 'src/app/shared/enums/navigation';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  locationSubject$: BehaviorSubject<Navigation> = new BehaviorSubject(null);
  private pageTitleSub: BehaviorSubject<string> = new BehaviorSubject(null);
  private patientUserIdSub: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor() { }

  getLocation(): Observable<Navigation> {
    return this.locationSubject$.asObservable();
  }

  setLocation(loc: Navigation) {
    this.locationSubject$.next(loc);
  }

  setTitle(title: string) {
    this.pageTitleSub.next(title);
  }
  get title$() {
    return this.pageTitleSub.asObservable();
  }
  get title() {
    return this.pageTitleSub.value;
  }

  setPatientUserId(id: number) {
    this.patientUserIdSub.next(id);
  }
  get patientUserId$() {
    return this.patientUserIdSub.asObservable();
  }
  get patientUserId() {
    return this.patientUserIdSub.value;
  }
}
