<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="dialog">
  <h2 mat-dialog-title>Reset EULA flags?</h2>
  <mat-dialog-content>{{message}}</mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button class="push-button warning" (click)="submit()">Reset</button>
    <button class="push-button tertiary" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>