import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { nameValidator, textValidator, textValidatorOptional, zipValidator } from '../../validators';
import { LocationService } from 'src/app/core/services/location.service';
import { ProductService } from 'src/app/core/http/product/product.service';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { states } from '../../enums/state';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {

  addressForm: FormGroup;
  stateOptions = states()


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _formBuilder:FormBuilder,
    private readonly navigationService:LocationService,
    private readonly productService:ProductService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddAddressComponent>
    ) { }
  ngOnInit(): void {
    this.addressForm = this._formBuilder.group({
      first_name: ['', [Validators.required, nameValidator()]],
      last_name: ['', [Validators.required, nameValidator()]],
      address_line_1: ['', [Validators.required]],
      address_line_2: ['', []],
      administrative_district_level_1: ['', [Validators.required, textValidatorOptional()]],
      locality: ['', [Validators.required]],
      postal_code: ['', [Validators.required, zipValidator()]],
      country: ['US', [Validators.required, textValidator()]],
      address_type: [this.data.addressType, [Validators.required]]
    });
  }

  recipientAddress() {
    let body = {
      userId: this.navigationService.patientUserId,
      Address: this.addressForm.value

    }
    this.productService.postRecipientAddress(this.navigationService.patientUserId, this.addressForm.value).toPromise().then((response) => {
      this.dialogRef.close({success:true})
    }).catch((error) => {
      this.dialog.open(MessageBoxComponent, {
        data: {
          title: 'Error Message',
          message:
            "Something Went wrong",
          buttonText: 'Ok'
        },
      });
    })
  }

  onStateChange(){
    return states().find((state)=>{
      return state.name==this.addressForm.get('administrative_district_level_1').value
    })
  }
  cancel(){
    this.dialogRef.close({success:false})

  }

}
