<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="dialog">
  <h2 mat-dialog-title>Upload Prescription</h2>
  <mat-dialog-content>
      <div *ngIf="!file">Choose a prescription file</div>
      <div *ngIf="file">File selected: {{file.name}}</div>
      <div style="color: red;" *ngIf="errorInvalidFileType">
        Invalid file type.
      </div>

  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <input #fileInput type="file" style="display: none;" (change)="fileChanged($event)" [accept]="acceptedFileTypes" />
    <button class="push-button primary" *ngIf="!file" (click)="fileInput.click()">Choose</button>
    <button class="push-button primary" *ngIf="file" (click)="submit()">Submit</button>
    <button class="push-button tertiary" (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</div>
