<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="confirm-popup">
    <mat-icon svgIcon="i-btn" style="width: 35px; height: 35px;margin-bottom: 5px;"></mat-icon>
    <h1>{{ title }}</h1>
    <div *ngIf="missingFields.length" style="text-align: center;padding: 5px 0px;">
        <span>One or more mandatory Rx fields are missing:</span>
        <div *ngFor="let field of missingFields" style="padding: 5px 0px;">{{field.fieldName}}</div>
    </div>
<footer>
    <h3 class="footer-text">{{ footerTitle }}</h3>
    <div class="action-button-container">
        <button [mat-dialog-close]="true" class="push-button tertiary" style="margin-right: 0.8em;">{{secondaryBtnName}}</button>
        <button (click)="onClick()" class="primary-submit-btn">{{primaryBtnName}}</button>
    </div>
</footer>
</div>