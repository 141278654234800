// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { ApplicationRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthService } from './core/services/auth.service';
import { IdleWarningDialogComponent } from './shared/components/idle-warning-dialog/idle-warning-dialog.component';
import { IdleWarningDialogData } from './shared/dialog-data.interface';
import { UserService } from './core/http/user/user.service';
import { LocationService } from './core/services/location.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: string = 'avalee-portal';
  IDLE_TIME: number = 10 * 60;    // Idle session timeout (in seconds)
  TIMEOUT_TIME: number = 1 * 60;  // Time to display idle warning prompt (in seconds)
  dialogRef: MatDialogRef<IdleWarningDialogComponent, IdleWarningDialogData> = null;

  constructor(
    private readonly idle: Idle,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly appRef: ApplicationRef,
    private readonly userService: UserService,
    private readonly locationService: LocationService,
  ) {
    this.idle.setIdle(this.IDLE_TIME);
    this.idle.setTimeout(this.TIMEOUT_TIME);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      if (this.authService.isAuthenticated()) {
        let data: IdleWarningDialogData = { countdown: this.TIMEOUT_TIME };
        this.dialogRef = this.dialog.open(IdleWarningDialogComponent, { data, disableClose: true });
      }
      else {
        this.reset();
      }
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
      this.appRef.tick();
    });

    this.idle.onTimeoutWarning.subscribe(seconds => {
      let data: IdleWarningDialogData = { countdown: seconds };
      this.dialogRef.componentInstance.data = data;
    });

    this.idle.onTimeout.subscribe(() => {
      this.authService.logout();
      this.reset();
    });
  }

  ngOnInit(): void {
    this.reset();
  }

  reset() {
    if (this.dialogRef != null) {
      this.dialog.closeAll();
      this.dialogRef = null;
    }
    this.idle.watch();
  }
}
