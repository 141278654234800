// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  userDetails: any;
  callbackFunction: Observable<any>;
  filters: string[] = [];
  columns: string[] = [];
  metadata: object = {};

  constructor() { }

  exportReportAsImage() {
    html2canvas(document.querySelector("#report")).then((canvas) => {
      const margin = [10, 10];

      var pdf = new jspdf('p', 'mm', 'a4');
      var pageWidth = pdf.internal.pageSize.getWidth();
      var pageHeight = pdf.internal.pageSize.getHeight();
      var innerPageWidth = pageWidth - margin[0] * 2;
      var innerPageHeight = pageHeight - margin[1] * 2;

      // Calculate the number of pages.
      var pxFullHeight = canvas.height;
      var pxPageHeight = Math.floor(canvas.width * (pageHeight / pageWidth));
      var nPages = Math.ceil(pxFullHeight / pxPageHeight);

      // Define pageHeight separately so it can be trimmed on the final page.
      var pageHeight = innerPageHeight;

      // Create a one-page canvas to split up the full image.
      var pageCanvas = document.createElement('canvas');
      var pageCtx = pageCanvas.getContext('2d');
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight;

      for (var page = 0; page < nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }

        // Display the page.
        var w = pageCanvas.width;
        var h = pageCanvas.height;
        pageCtx.fillStyle = 'white';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

        // Add the page to the PDF.
        if (page > 0) pdf.addPage();
        var imgData = pageCanvas.toDataURL('image/PNG');
        pdf.addImage(imgData, 'PNG', margin[1], margin[0], innerPageWidth, pageHeight);
      }

      pdf.save('report.pdf');
    });
  }

  exportReport() {
    const margin = [10, 10];
    const htmlElement = document.querySelector("#report") as HTMLElement;

    const pdf = new jspdf('p', 'mm', 'a4');

    pdf.html(htmlElement, {
      margin: margin,
      html2canvas: {
        letterRendering: true,
        scale: .2,
      },
      callback: function(pdf) {
        pdf.save('report.pdf');
      }
    });
  }

  setUserDetails(details: any) {
    this.userDetails = details;
  }

  getUserDetails() {
    return this.userDetails;
  }

  setCallback(fn: any): void {
    this.callbackFunction = fn;
  }

  getCallback(): Observable<any> {
    return this.callbackFunction;
  }

  setFilters(filters: string[]) {
    this.filters = filters;
  }

  getFilterStr(): string {
    return this.filters.join(', ');
  }

  setColumns(columns: string[]) {
    this.columns = columns;
  }

  getColumns(): string[] {
    return this.columns
  }

  setMetadata(key: string, value: any) {
    this.metadata[key] = value;
  }

  getMetadata(key: string): any {
    return this.metadata[key];
  }
}
