// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserPrescriptionService } from 'src/app/core/http/user-prescription/user-prescription.service';
import { UserService } from 'src/app/core/http/user/user.service';
import { User } from 'src/app/shared/models/user.interface';

@Component({
  selector: 'app-delete-prescription-modal',
  templateUrl: './delete-prescription-modal.component.html',
  styleUrls: ['./delete-prescription-modal.component.scss']
})
export class DeletePrescriptionModalComponent implements OnInit {

  user$: Observable<User>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {userId: number}, 
    private matDialogRef: MatDialogRef<DeletePrescriptionModalComponent>, 
    private userService: UserService,
    private userPrescriptionService: UserPrescriptionService) {
    this.user$ = this.userService.read(data.userId);
  }

  ngOnInit(): void {
  }

  submit() {
    this.userPrescriptionService.delete(this.data.userId).pipe(take(1)).subscribe(() => {
      this.matDialogRef.close(true);
    })
  }

}
