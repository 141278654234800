// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { GetTheAppComponent } from './ui/home/get-the-app/get-the-app.component';
import { AcknowledgementsComponent } from './ui/acknowledgements/acknowledgements.component';
import { RightResponsibilityComponent } from './ui/right-responsibility/right-responsibility.component';
import { AccountComponent } from './ui/account/account.component';
import { DeleteAcountComponent } from './ui/delete-acount/delete-account.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../app/ui/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'get-the-app',
    component: GetTheAppComponent
  },
  {
    path: 'acknowledgements',
    component: AcknowledgementsComponent
  },
  // {
  //   path: 'account',
  //   component: AccountComponent
  // },
  {
    path: 'delete-account',
    component: DeleteAcountComponent
  },
  {
    path: '',
    canActivate: [AuthService],
    loadChildren: () => import('../app/ui/home/home.module').then(m => m.HomeModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
