// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AccountService } from 'src/app/core/http/account/account.service';
import { ResetEulaDialogData } from '../../dialog-data.interface';
import { ClinicService } from 'src/app/core/http/clinic/clinic.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'reset-eula-dialog',
  templateUrl: './reset-eula-dialog.component.html',
  styleUrls: ['./reset-eula-dialog.component.scss']
})
export class ResetEulaDialogComponent implements OnInit {
  message: string;
  http: Observable<number[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ResetEulaDialogData,
    private accountService: AccountService,
    private clinicService: ClinicService,
    private dialogRef: MatDialogRef<ResetEulaDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.userIds) {
      this.message = `This will reset the Accepted EULA flag for ${this.data.userIds.length} user(s). This cannot be undone.`;
      this.http = this.accountService.resetEula(this.data.userIds);
    }
    else if (this.data.clinicId) {
      if (!this.data.role || !['patient', 'clinician'].includes(this.data.role)) {
        console.error('Role must be provided for resetting a clinics EULA');
      }

      this.clinicService.read(this.data.clinicId).subscribe(clinic => {
        this.message = `This will reset the Accepted EULA flag for every ${this.data.role === 'patient' ? 'patient' : 'clinician'} assigned to ${clinic.name}`;
      })

      if (this.data.role == 'patient') {
        this.http = this.clinicService.resetEulaForPatients(this.data.clinicId);
      }
      else {
        this.http = this.clinicService.resetEulaForClinicians(this.data.clinicId);
      }
    }
    else {
      console.error('Either userId or clinicId must be provided to ResetEulaDialog')
    }
  }

  async submit() {
    await this.http.pipe(take(1)).toPromise();
    this.dialogRef.close();
  }
}
