// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumber } from '../classes/phone-number';

@Pipe({name: 'phoneNumber'})
export class PhoneNumberPipe implements PipeTransform {
    transform(value: string | PhoneNumber, ext: string = null): string {
        if (typeof value === 'string') {
            value = PhoneNumber.fromString(value);
        }
        if(value) {
            value.extension = ext;
            return value.toString();
        }
        return null;
    }
}