import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'centsToDollar'
})
export class CentsToDollarPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value){
      return value/100
    }
    return 0
  }

}
