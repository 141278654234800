// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { PushNotificationsService } from 'src/app/core/http/pushnotifications/push-notifications.service';
import { LocationService } from 'src/app/core/services/location.service';
import { ReportService } from 'src/app/core/services/report.service';
import { SelectOption } from 'src/app/shared/models/select-option.interface';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  errorDuringSend: boolean = false;
  errorInvalidMessage: boolean = false;

  formGroup: FormGroup = this.formBuilder.group({
    messageSelect: [null],
    messageText: [null]
  });

  studies: SelectOption[] = [
    { value: "Don't forget to do your therapy session", viewValue: "Don't forget to do your therapy session" },
    { value: "Remember to do eDiary", viewValue: "Remember to do eDiary" },
    { value: "New Notification", viewValue: "New notification" },
    { value: "", viewValue: "Custom" }
  ];
  constructor(
    private readonly formBuilder: FormBuilder, 
    private readonly pushNotificationsService: PushNotificationsService,
    public readonly locationService: LocationService,
    private readonly reportService: ReportService,
    private dialogRef: MatDialogRef<SendNotificationComponent>
  ) { }

  ngOnInit(): void {
  }

  onMessageSelectChange() {
    this.formGroup.patchValue({ messageText: this.formGroup.get('messageSelect').value });
    this.errorInvalidMessage = false;
  }

  onKeyup() {
    this.errorInvalidMessage = false;
  }

  async onSubmit() {
    this.errorInvalidMessage = false;

    if (this.formGroup.valid) {
      this.errorDuringSend = false
      var patientUserId = this.locationService.patientUserId;
      
      if (patientUserId != null) {
        try {
          await this.pushNotificationsService.send([patientUserId], this.formGroup.get('messageText').value).toPromise();
        }
        catch(err) {
          this.errorDuringSend = true;
        }
      }
      else {
        try {
          let reportCallbackResponse = await this.reportService.getCallback().pipe(take(1)).toPromise();
          var ids = reportCallbackResponse.map(x => x.userId);

          await this.pushNotificationsService.send(ids, this.formGroup.get('messageText').value).pipe(take(1)).toPromise();
        }
        catch(err) {
          this.errorDuringSend = true;
        }
      }

      if(!this.errorDuringSend) {
        this.dialogRef.close();
      }
    } else {
      this.errorInvalidMessage = true;
    }
  }
}
