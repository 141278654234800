// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ReportService } from "src/app/core/services/report.service";
import { FluidUnit } from "src/app/shared/enums/fluid-unit";
import { APIDataSource } from "src/app/shared/helpers/api-data-source";


@Component({
  selector: 'patient-journal-report',
  templateUrl: './patient-journal-report.component.html',
  styleUrls: ['./patient-journal-report.component.scss']
})
export class PatientJournalReport implements OnInit {

  chartData: any[] = [];
  date: Date = new Date();
  dataSource: APIDataSource<any>;
  patient: any;
  displayedColumns: string[];
  selectedFluidUnit: FluidUnit;

  constructor(
    public readonly reportService: ReportService,
    public readonly datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.reportService.getCallback().subscribe(res => {
      this.dataSource = res;
      this.chartData = res;
    });
    this.patient = this.reportService.getUserDetails();
    this.displayedColumns = this.reportService.getColumns();
    this.selectedFluidUnit = this.reportService.getMetadata("selectedFluidUnit");
  }
}