// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/token-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { NgIdleModule } from '@ng-idle/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AppSettingsModule } from './app-settings/app-settings.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { AcknowledgementsModule } from './ui/acknowledgements/acknowledgements.module';
import { RightResponsibilityModule } from './ui/right-responsibility/right-responsibility.module';
import { AccountComponent } from './ui/account/account.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { DeleteAcountComponent } from './ui/delete-acount/delete-account.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({
  declarations: [AppComponent, AccountComponent, DeleteAcountComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ChartsModule,
    NgIdleModule.forRoot(),
    MatDialogModule,
    AppSettingsModule.forRoot(),
    MatNativeDateModule,
    MatButtonModule,
    AcknowledgementsModule,
    RightResponsibilityModule,
    MatIconModule,
    SharedModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
