// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'foot'})
export class FootPipe implements PipeTransform {
    transform(value: number, bothFeet: boolean = false): string {
        var footStr = (value === 0 ? 'Left' : '') + (value === 1 ? 'Right' : '') + (value === 2 ? 'Left + Right' : '');
        if (bothFeet) {
            footStr = 'Left + Right';
        }
        return footStr;
    }
}