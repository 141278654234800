// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserDetail, UserCreate, UserUpdate, UserPermissions, UserReportQuery, UserReport } from 'src/app/shared/models/user.interface';
import { APIQuery } from 'src/app/shared/models/api-query.interface';
import { PagedResult } from 'src/app/shared/models/paged-result.interface';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private readonly http: HttpClient, private readonly appSettingsService: AppSettingsService) { }

  public getMyUser() {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<UserDetail>(`${appSettings.apiBaseUrl}/v1.0/users/me`))
    );
  }

  public getMyPermissions() {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<UserPermissions>(`${appSettings.apiBaseUrl}/v1.0/users/me/permissions`))
    );
  }

  public query(apiQuery?: APIQuery, role?: string, firstName?: string, lastName?: string, email?: string) {
    let params: any = {};

    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    if (!!role) {
      params.role = role;
    }
    if (!!firstName) {
      params.firstName = firstName;
    }
    if (!!lastName) {
      params.lastName = lastName;
    }
    if (!!email) {
      params.email = email;
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<User>>(`${appSettings.apiBaseUrl}/v1.0/users/query`, { params }))
    );
  }

  public queryReport(apiQuery?: APIQuery, query?: UserReportQuery): Observable<PagedResult<UserReport>> {
    let params = Object.entries(query).reduce((a, [k,v]) => (v == null ? a : (a[k]=v, a)), {});
    params = Object.assign({}, apiQuery, params);

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<UserReport>>(`${appSettings.apiBaseUrl}/v1.0/users/query/report`, { params }))
    );
  }

  public queryTable(apiQuery?: APIQuery, query?: UserReportQuery): Observable<PagedResult<UserReport>> {
    let params = Object.entries(query).reduce((a, [k,v]) => (v == null ? a : (a[k]=v, a)), {});
    params = Object.assign({}, apiQuery, params);

    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<UserReport>>(`${appSettings.apiBaseUrl}/v1.0/users/query/table`, { params }))
    );
  }

  public getUsersInGroup(group: string) {
    const params = {
      group
    };
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<User[]>(`${appSettings.apiBaseUrl}/v1.0/users/usersInGroup`, { params }))
    );
  }

  public getRoles() {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<string[]>(`${appSettings.apiBaseUrl}/v1.0/users/groups`))
    );
  }

  public read(userId: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<User>(`${appSettings.apiBaseUrl}/v1.0/users/${userId}`))
    );
  }

  public readDetail(userId: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<UserDetail>(`${appSettings.apiBaseUrl}/v1.0/users/${userId}/detail`))
    );
  }

  public delete(userId: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.delete(`${appSettings.apiBaseUrl}/v1.0/users/${userId}`))
    );
  }

  public create(model: UserCreate) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<User>(`${appSettings.apiBaseUrl}/v1.0/users`, model))
    );
  }

  public createSignup(model: UserCreate) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<User>(`${appSettings.apiBaseUrl}/v1.0/users/signup`, model))
    );
  }

  public update(userId: number, model: UserUpdate) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<User>(`${appSettings.apiBaseUrl}/v1.0/users/${userId}`, model))
    );
  }

  public isEnabled(userId: number): Observable<boolean> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<boolean>(`${appSettings.apiBaseUrl}/v1.0/users/${userId}/isenabled`))
    )
  }

  public enable(userId: number): Observable<void> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<void>(`${appSettings.apiBaseUrl}/v1.0/users/${userId}/enable`, {}))
    ) 
  }

  public disable(userId: number): Observable<void> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<void>(`${appSettings.apiBaseUrl}/v1.0/users/${userId}/disable`, {}))
    ) 
  }

  public verifyUser(email) {
    let payload={
      email:email
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<User>(`${appSettings.apiBaseUrl}/v1.0/account/users/check-email`,payload ))
    );
  }
  public createNewAccount(payload: any): Observable<void> {
    let body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phoneNumber: payload.phone,
        role: "Patient",
        status: 0,
        isPatientCheckbox: payload.isPatientCheckbox
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<void>(`${appSettings.apiBaseUrl}/v1/account/users/registration/requests`, body))
    )
  }

  public getAccountRequests(apiQuery: any,firstName,lastName,email,phone,status) {
    let params: any = {}

    if (!!lastName) {
      params.lastNameLike = lastName;
    }
    if (!!firstName) {
      params.firstNameLike = firstName;
    }
    if (!!email) {
      params.emailLike = email;
    }
    if (!!phone) {
      params.phoneNumberLike = phone;
    }
    if (!!status) {
      params.status = status;
    }
    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    return this.appSettingsService.appSettings$.pipe(
        switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1/account/users/registration/requests`, {params}))
    );
  }
  updateRequest(id,status){
    let body={
      id:id,
      status:status
    }
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<void>(`${appSettings.apiBaseUrl}/v1/account/users/registration`, body))
    )
  }
}
