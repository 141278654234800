// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { ComponentType } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/core/http/user/user.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ExportService } from 'src/app/core/services/export.service';
import { IconService } from 'src/app/core/services/icon.service';
import { LocationService } from 'src/app/core/services/location.service';
import { Navigation } from 'src/app/shared/enums/navigation';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { UserDetail } from 'src/app/shared/models/user.interface';
import { ClinicianSnapshotReport } from 'src/app/ui/report/clinician-snapshot-report/clinician-snapshot-report.component';
import { PatientTherapyReport } from 'src/app/ui/report/patient-therapy-report/patient-therapy-report.component';
import { PatientJournalReport } from 'src/app/ui/report/patient-journal-report/patient-journal-report.component';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isSidebarOpen: boolean = false;
  navToggle: string = 'dehaze';

  @Input() showUserMenu: boolean = true;
  @Input() showBurgerMenu: boolean = true;

  location$: Observable<Navigation>;
  pageTitle: string;
  user$: Observable<UserDetail>;
  title: string = "";
  testClinic: boolean = false;
  reportType: ComponentType<unknown>;

  private _displayNotification: boolean;
  private _displayExport: boolean;
  private _displayReport: boolean;

  constructor(
    public readonly authService: AuthService,
    private readonly navigationService: LocationService,
    private readonly exportService: ExportService,
    private readonly iconService: IconService,
    private readonly userService: UserService,
    public dialog: MatDialog,
    public UserSettingsService: UserSettingsService,
    private readonly router:Router
  ) {
    this.iconService.registerIcons();
    this.location$ = this.navigationService.getLocation();
    this.user$ = this.userService.getMyUser();
    this.UserSettingsService.sidebarOpen$.subscribe(isOpen => {
      this.isSidebarOpen = isOpen;
    });
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.UserSettingsService.setSidebarOpen(this.isSidebarOpen);
  }

  async ngOnInit(): Promise<void> {
    this.location$.subscribe(loc => {
      this.reportType = null;
      this._displayNotification = false;
      this._displayExport = false;
      this._displayReport = false;

      switch (loc) {
        case Navigation.Patients:
          this._displayNotification = true;
          this._displayExport = true;
          this._displayReport = true;
          this.reportType = ClinicianSnapshotReport;
          break;
        case Navigation.PatientInfo:
          this._displayNotification = true;
          break;
        case Navigation.Therapy:
          this._displayNotification = true;
          this._displayExport = true;
          this._displayReport = true;
          this.reportType = PatientTherapyReport;
          break;
        case Navigation.Journal:
          this._displayNotification = true;
          this._displayExport = true;
          this._displayReport = true;
          this.reportType = PatientJournalReport;
          break;
      }
    });

    this.navigationService.title$.subscribe(x => {
      this.pageTitle = x;
    });

    if(this.showUserMenu) {
      this.userService.getMyUser().subscribe(x => {
        this.testClinic = x.testClinic
      });
    }
  }

  get showBurgerMenuStyle() {
    return this.showBurgerMenu ? '' : '0';
  }

  displayNotification(): boolean {
    if (!this._displayNotification) {
      return false;
    }

    let allowedRoles = ['Admin', 'Clinician'];
    return this.authService.getRoles().some(r => allowedRoles.includes(r));
  }

  openNotificationDialog(): void {
    this.dialog.open(SendNotificationComponent, {
      height: '400px',
      width: '600px',
      panelClass: 'send-notification'
    });
  }

  displayExport(): boolean {
    if (!this._displayExport) {
      return false;
    }

    let allowedRoles = ['Admin', 'Engineer', 'CustomerCare'];
    return this.authService.getRoles().some(r => allowedRoles.includes(r));
  }

  export(): void {
    this.exportService.callCallback();
  }

  displayReport(): boolean {
    if (!this._displayReport) {
      return false;
    }

    let fullPermission = ['Admin', 'Engineer'];
    let testOnly = ['Clinician', 'Patient'];

    let roles = this.authService.getRoles();
    for (let i = 0; i < roles.length; i++) {
      if (fullPermission.includes(roles[i])) {
        return true
      }
      else if (testOnly.includes(roles[i]) && this.testClinic) {
        return true
      }
    }
    return false;
  }

  openReportDialog(): void {
    this.dialog.open(this.reportType, {
      height: '90%', maxWidth: '1000px'
    });
  }

  displayHelp(): boolean {
    return false;
  }

  openHelpDialog(): void {
    
  }
  isPatient(){
    return this.authService.hasRole('Patient')
  }
myProfile(){
  if(this.isPatient()){
    this.router.navigate(['/patients',this.navigationService.patientUserId])
  }
}
  logout(): void {
    this.authService.logout();
  }
}
