// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdleWarningDialogData } from '../../dialog-data.interface';

@Component({
  selector: 'idle-warning-dialog',
  templateUrl: './idle-warning-dialog.component.html',
  styleUrls: ['./idle-warning-dialog.component.scss']
})
export class IdleWarningDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IdleWarningDialogData
  ) {
  }

  ngOnInit(): void {
  }
}
