// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'batteryLevelFormat'})
export class BatteryLevelFormatPipe implements PipeTransform {
    transform(value: number): string {
        return `${(value * 0.000001).toFixed(4)} V`;
    }
}