import { Component, OnInit } from '@angular/core';
import { IconService } from 'src/app/core/services/icon.service';

@Component({
  selector: 'app-delete-acount',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAcountComponent implements OnInit {

  constructor(
    private readonly iconService: IconService
  ) {
    this.iconService.registerIcons();
  }

  ngOnInit(): void {
  }

}
