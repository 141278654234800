<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="dialog horizontal">
  <img
    style="width: 2rem; margin-right: 15px"
    *ngIf="!data.color"
    src="assets/icons/addRecord.svg"
  />
  <img
    style="width: 2rem; margin-right: 15px"
    *ngIf="data.color"
    src="assets/icons/check_circle.svg"
  />
  <p [ngStyle]="{ color: textColor }" style="font-weight: 500; margin: 0">
    {{ data.message }}
  </p>
  <div class="close-button">
    <img
      [mat-dialog-close]="true"
      src="assets/icons/xBtn.svg"
    />
  </div>
</div>
