// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { integerValidator } from '../../validators';
import { PhoneNumber } from '../../classes/phone-number';


@Component({
  selector: 'phone-number-input',
  templateUrl: 'phone-number-input.component.html',
  styleUrls: ['phone-number-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: PhoneNumberInput}],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class PhoneNumberInput implements ControlValueAccessor, MatFormFieldControl<PhoneNumber>, OnDestroy {
  static nextId = 0;
  @ViewChild('area') areaInput: HTMLInputElement;
  @ViewChild('exchange') exchangeInput: HTMLInputElement;
  @ViewChild('subscriber') subscriberInput: HTMLInputElement;
  @ViewChild('extension') extensionInput: HTMLInputElement;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  enableExtension = true;
  controlType = 'phone-number-input';
  id = `phone-number-input-${PhoneNumberInput.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: {area, exchange, subscriber, extension},
    } = this.parts;

    return !area && !exchange && !subscriber && !extension;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): PhoneNumber | null {
    if (this.empty) {
      return null;
    }

    const {
      value: {area, exchange, subscriber, extension},
    } = this.parts;
    return new PhoneNumber(area, exchange, subscriber, extension);
  }
  set value(tel: PhoneNumber | null) {
    if (typeof tel === 'string') {
      tel = PhoneNumber.fromString(tel);
    }
    const {area, exchange, subscriber, extension} = tel || new PhoneNumber('', '', '');
    this.parts.setValue({area, exchange, subscriber, extension});
    this.stateChanges.next();
  }

  @Input()
  get ext() {
    return this.enableExtension;
  }
  set ext(e) {
    this.enableExtension = typeof e !== 'string' ? e : e === 'true';
    if (!this.enableExtension) {
      this.parts.controls['extension'].disable();
    }
    this.stateChanges.next();
  }

  get errorState(): boolean {
    const newState = (this.ngControl?.invalid || (this.parts.invalid && !this.empty)) && this.ngControl.touched;

    if (this._errorState !== newState) {
      this._errorState = newState;
      this.stateChanges.next();
    }
    return this._errorState;
  }
  private _errorState = false;

  
  constructor(
    formBuilder: FormBuilder,
    private readonly _focusMonitor: FocusMonitor,
    private readonly _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.parts = formBuilder.group({
      area: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3), integerValidator()]],
      exchange: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3), integerValidator()]],
      subscriber: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), integerValidator()]],
      extension: [null, [integerValidator()]]
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      let target = event.target as HTMLInputElement;
      target.select();
      
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (control.value && !control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
      nextElement.select();
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control && control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
      prevElement.select();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.phone-number-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if (this.parts.valid && !this.empty) {
      return;
    }

    if (this.parts.controls.extension.value && this.parts.controls.extension.valid) {
      this._focusMonitor.focusVia(this.extensionInput, 'program');
    } else if (this.parts.controls.subscriber.value && this.parts.controls.subscriber.valid) {
      this._focusMonitor.focusVia(this.extensionInput, 'program');
    } else if (this.parts.controls.exchange.value && this.parts.controls.exchange.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.area.value && this.parts.controls.area.valid) {
      this._focusMonitor.focusVia(this.exchangeInput, 'program');
    } else {
      this._focusMonitor.focusVia(this.areaInput, 'program');
    }
  }

  writeValue(tel: PhoneNumber | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}
