import { UserService } from 'src/app/core/http/user/user.service';
import { IconService } from 'src/app/core/services/icon.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator, nameValidator, nonWhitespaceValidator, phoneValidator } from 'src/app/shared/validators';
import { PhoneNumber } from 'src/app/shared/classes/phone-number';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/shared/components/message-box/message-box.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  form: FormGroup;
  successMessage: string;
  errorMessage: boolean= false;
  constructor(
    private readonly iconService: IconService,
    private fb: FormBuilder,
    private userService: UserService,
    private dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router:Router
  ) {
    this.iconService.registerIcons();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, nonWhitespaceValidator()]],
      lastName: ['', [Validators.required, nonWhitespaceValidator()]],
      email: ['', [Validators.required, emailValidator()]],
      confirmationEmail: ['', [Validators.required, emailValidator()]],
      phone: ['',[Validators.required, phoneValidator()]],
      isPatientCheckbox: [false],
    });
    // Subscribe to value changes of email and confirmationEmail fields
    this.form.get('email').valueChanges.subscribe(() => this.validateEmails());
    this.form.get('confirmationEmail').valueChanges.subscribe(() => this.validateEmails());
    const email = this.route.snapshot.queryParams['email'];
    this.form.get('email').disable()
    this.form.patchValue({
      email:email
    })
  }

  validateEmails(): void {
    const email = this.form.get('email').value;
    const confirmationEmail = this.form.get('confirmationEmail').value;

    if (email !== confirmationEmail) {
      this.form.get('confirmationEmail').setErrors({ emailsNotSame: true });
    } else {
      this.form.get('confirmationEmail').setErrors(null);
    }
  }

  // Method to submit the form
  onSubmit(): void {
    if (this.form.valid) {
      // Convert phone field value
      let phoneVal = this.form.get('phone').value;
      let phone = typeof phoneVal === 'string' ? PhoneNumber.fromString(phoneVal) : phoneVal;
      this.form.patchValue({ phone: phone?.toRawString() });

      const formData = { ...this.form.getRawValue() };
      delete formData.isPatientCheckbox; // Exclude checkbox value from form data

      this.userService.createNewAccount(formData).toPromise().then((response) => {
        this.form.reset(); // Resets the form values
        this.form.disable();
        this.dialog.open(MessageBoxComponent, {
          data: {
            title: 'Account Request Submitted',
            message: "You will receive an email when your request has been approved.",
            buttonText: 'Ok',
          },
        }).afterClosed().subscribe(() => {
          this.router.navigate(['login'])
        })
      }).catch((error) => {
        this.errorMessage = error.error.message;
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

}
