<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->
<style>
    a:hover {
        color: #7b91a8;
        transition: 0.3s ease-out;
    }
</style>
<div>
    <div class="dialog-header">
        <mat-icon class="download-icon" svgIcon="download"></mat-icon>
        <span>Download Monthly Invoices</span>
    </div>
    <div class="dialog-body">
        <div *ngIf="invoices$ | async as invoices;else loading">
            <div  *ngFor="let invoice of dataSource">
              <div class="row" *ngIf="invoice.status=='PAID' && data.download">
                <span>{{invoice.updated_at |date: 'MMM d, yyyy HH:mm:ss'}}</span>
                <span style="font-weight: bold;color: #213b58;cursor: pointer;" (click)="download(invoice.subscription_id,invoice.id)">Download</span>
              </div>
              <div class="row" *ngIf="!(invoice.status=='PAID') && !data.download">
                <span>{{invoice.updated_at |date: 'MMM d, yyyy HH:mm:ss'}}</span>
                <span *ngIf="!(invoice.status=='PAID') && !data.download" style="font-weight: bold;color: #213b58;cursor: pointer;"><a style="color:#213b58 ;" target="_blank" href="{{invoice.public_url}}">Pay Now</a></span>
            </div>
            </div>
            <div>
                <div class="row" *ngIf="data.invoiceIds">
                  <span>Last Invoice</span>
                  <span style="font-weight: bold;color: #213b58;cursor: pointer;" (click)="download(subscriptionId,data.invoiceIds[1])">Download</span>
                </div>
              </div>
        </div>
        <ng-template #loading>
            <mat-progress-spinner color="primary" mode="indeterminate"
                        style="margin: 0 auto; position: absolute; left: 37%"></mat-progress-spinner>
        </ng-template>
    </div>
    <div class="dialog-action">
        <button (click)="cancel()">Cancel</button>
    </div>
</div>