// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { APIQuery } from 'src/app/shared/models/api-query.interface';
import { PagedResult } from 'src/app/shared/models/paged-result.interface';
import { Subject, SubjectReport } from 'src/app/shared/models/subject';


@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor(private readonly http: HttpClient, private readonly appSettingsService: AppSettingsService) { }

  public readByUserId(userId: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<Subject>(`${appSettings.apiBaseUrl}/v1.0/subject/user/${userId}`))
    );
  }

  public queryReport(apiQuery?: APIQuery, userId?: number, clinicId?: number, clinicianId?: number, studyId?: number, studyGroup?: string, patientId?: string,
    firstName?: string, lastName?: string, email?: string, phoneNumber?: string, extension?: string, activityStart?: Date, activityEnd?: Date, maxTherapyCompliance?: number,
    isTestPatient?: boolean) {
    let params: any = { };

    if(!!userId) {
      params.userId = userId;
    }
    if(!!clinicId) {
      params.clinicId = clinicId;
    }
    if(!!clinicianId) {
      params.clinicianId = clinicianId;
    }
    if(studyId !== null) {
      params.studyId = studyId;
    }
    if(!!studyGroup) {
      params.studyGroup = studyGroup;
    }
    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    if(!!patientId) {
      params.patientId = patientId;
    }
    if(!!firstName) {
      params.firstName = firstName;
    }
    if(!!lastName) {
      params.lastName = lastName;
    }
    if(!!email) {
      params.email = email;
    }
    if(!!phoneNumber) {
      params.phoneNumber = phoneNumber;
    }
    if(!!extension) {
      params.extension = extension;
    }
    if(!!activityStart) {
      params.activityStart = activityStart.toISOString();
    }
    if(!!activityEnd) {
      params.activityEnd = activityEnd.toISOString();
    }
    if(!!maxTherapyCompliance) {
      params.maxTherapyCompliance = maxTherapyCompliance;
    }
    if(isTestPatient === true || isTestPatient === false) {
      params.isTestPatient = isTestPatient;
    }
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1.0/subject/query/report`, {params}))
    );
  }

  public exportReport(apiQuery?: APIQuery, userId?: number, clinicId?: number, studyId?: number, studyGroup?: string, firstName?: string, lastName?: string, patientId?: string, clinicianId?: number) {
    let params: any = { };
    
    if(!!userId) {
      params["userId"] = userId;
    }

    if(!!clinicId) {
      params["clinicId"] = clinicId;
    }

    if(!!studyId) {
      params.studyId = studyId;
    }

    if(!!studyGroup) {
      params.studyGroup = encodeURIComponent(studyGroup);
    }

    if(!!firstName) {
      params.firstName = firstName;
    }
    if(!!lastName) {
      params.lastName = lastName;
    }

    if(!!patientId) {
      params.patientId = patientId;
    }

    if(!!clinicianId) {
      params.clinicianId = clinicianId;
    }
    
    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1.0/subject/export/report`, { params, observe: 'response', responseType: 'blob' as 'json' }))
    );
  }

  public querySubjects(apiQuery?: APIQuery, clinicId?: number) {
    let params = { };
    
    if(clinicId) {
      params['clinicId'] = clinicId.toString()
    }
    
    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<Subject>>(`${appSettings.apiBaseUrl}/v1.0/subject/querysubjects`, {params}))
    );
  }

  public querySubjectsReport(apiQuery?: APIQuery, userId?: number, clinicId?: number) {
    let params: any = { };
    
    if(userId) {
      params.userId = userId;
    }

    if(clinicId) {
      params.clinicId = clinicId;
    }
    
    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<SubjectReport>>(`${appSettings.apiBaseUrl}/v1.0/subject/query/report`, { params }))
    );
  }

  public queryTable(apiQuery?: APIQuery, userId?: number, clinicId?: number, clinicianId?: number, studyId?: number, studyGroup?: string, patientId?: string,
    firstName?: string, lastName?: string, email?: string, phoneNumber?: string, extension?: string, activityStart?: Date, activityEnd?: Date, maxTherapyCompliance?: number,
    isTestPatient?: boolean) {
    let params: any = { };

    if(!!userId) {
      params.userId = userId;
    }
    if(!!clinicId) {
      params.clinicId = clinicId;
    }
    if(!!clinicianId) {
      params.clinicianId = clinicianId;
    }
    if(studyId !== null) {
      params.studyId = studyId;
    }
    if(!!studyGroup) {
      params.studyGroup = studyGroup;
    }
    if(apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }
    if(!!patientId) {
      params.patientId = patientId;
    }
    if(!!firstName) {
      params.firstName = firstName;
    }
    if(!!lastName) {
      params.lastName = lastName;
    }
    if(!!email) {
      params.email = email;
    }
    if(!!phoneNumber) {
      params.phoneNumber = phoneNumber;
    }
    if(!!extension) {
      params.extension = extension;
    }
    if(!!activityStart) {
      params.activityStart = activityStart.toISOString();
    }
    if(!!activityEnd) {
      params.activityEnd = activityEnd.toISOString();
    }
    if(!!maxTherapyCompliance) {
      params.maxTherapyCompliance = maxTherapyCompliance;
    }
    if(isTestPatient === true || isTestPatient === false) {
      params.isTestPatient = isTestPatient;
    }
    
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get<PagedResult<any>>(`${appSettings.apiBaseUrl}/v1.0/subject/query/table`, {params}))
    );
  }

  public update(id: number, record: Partial<Subject>): Observable<number> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<number>(`${appSettings.apiBaseUrl}/v1.0/subject/${id}`, record))
    );
  }
  public updateDemographics(record: Partial<Subject>): Observable<number> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<number>(`${appSettings.apiBaseUrl}/v1.0/subject/demographics`, record))
    );
  }

  public create(model: any) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.post(`${appSettings.apiBaseUrl}/v1.0/screening/patient`, model)
      )
    );
  }
}
