import { filter } from 'rxjs/operators';
// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'product'
})
export class ProductPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
      if(value && value[0]?.custom_attribute_values){
        let variation
        if(args[0]){
          variation=value.filter((x:any)=>x.item_variation_data.name == args[0])[0];
        }
        if (!variation) {
          variation=value[0]
        }
        let model:any=Object.values(variation.custom_attribute_values).filter((x:any)=>x.name=='model')[0]
        return model.string_value
      }
      return ''
  }

}
