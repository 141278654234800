// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AppSettings } from './app-settings';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private httpClient: HttpClient;

  private appSettings: AppSettings;
  public appSettings$: Observable<AppSettings>;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
    
    this.appSettings$ = this.httpClient.get<AppSettings>('/config/settings.json').pipe(
      tap(appSettings => this.appSettings = appSettings),
      shareReplay(1)
    );
  }

  async initialize() {
    return this.appSettings$.toPromise();
  }

  get apiBaseUrl() {
    return this.appSettings.apiBaseUrl;
  }

  get appId() {
    return this.appSettings.appId;
  }

  get locationId() {
    return this.appSettings.locationId;
  }

  get environment() {
    return this.appSettings.environment;
  }
  
  get testPaymentSupport() {
    return this.appSettings.test_payment_support;
  }
}
