// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AppSettingsService } from './app-settings.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: []
})
export class AppSettingsModule {

  static forRoot(): ModuleWithProviders<AppSettingsModule> {
    return {
      ngModule: AppSettingsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          deps: [AppSettingsService],
          useFactory: (appSettingsService: AppSettingsService) => () => appSettingsService.initialize()
        },
        AppSettingsService
      ]
    };
  }
}
