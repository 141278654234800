<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="digit-box-input-container" [formGroup]="parts" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
       <div *ngFor="let part of parts.controls; let i = index">
              <input integer class="digit-box-part" 
                     [formControlName]="i"
                     maxLength="1"
                     (input)="_handleInput(i)"
                     (keyup.backspace)="autoFocus(i-1)"
                     #digitInput>
       </div>
</div>
