import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'installment'
})
export class InstallmentPipe implements PipeTransform {
upfrontAmount:number=0;
downPayment:number=0;
monthlyInstallment:number=0;
discounts:number=0
originalAmount:number=0;
amountFinanced:number=0;
  transform(value: any, ...args: any[]): any {
    this.monthlyInstallment=0
    this.upfrontAmount=0;
    this.downPayment=0;
    this.discounts=0;
    this.amountFinanced=0

    if(args[0]=='period'){
      if(value){
        return value=='emi-payment-12'?'12':value=='emi-payment-18'?'18':value=='emi-payment-24'?'24':value=='emi-payment-36'?'36':'3'
      }
    }
    value.map((x)=>{
      if(args[0]=='discounts'){
        this.discounts+=x.metadata.discount?Number(x.metadata.discount):0
      }
      if(args[0]=='originalOrderAmount'){
        this.originalAmount+=x.metadata.originalItemPrice?Number(x.metadata.originalItemPrice)*Number(x.quantity):0
      }
      if(args[0]=='amountFinanced'){
        let itemDiscount=x.metadata.discount?Number(x.metadata.discount):0
        this.amountFinanced+=(x.base_price_money.amount-x.total_discount_money.amount+itemDiscount-Number(x.metadata.originalItemPrice) )*x.quantity
      }
      if(x.metadata.upfrontAmount && args[0]=='upfrontAmount'){
        this.upfrontAmount+=Number(x.metadata.upfrontAmount)*Number(x.quantity)
      }else if(x.metadata.monthlyInstallment && args[0]=='monthlyInstallment'){
        this.monthlyInstallment+=Number(x.metadata.monthlyInstallment)*Number(x.quantity)
      }else if(!x.metadata.upfrontAmount && !x.metadata.monthlyInstallment){
        this.downPayment+=x.total_money.amount
      }
    })
    if(args[0]=='upfrontAmount'){
      return this.upfrontAmount
    }else if(args[0]=='monthlyInstallment'){
      return this.monthlyInstallment
    }else if(args[0]=='discounts'){
      return this.discounts
    }else if(args[0]=='originalOrderAmount'){
      return this.originalAmount
    }else if(args[0]=='amountFinanced'){
      return this.amountFinanced
    }else{
      return this.downPayment
    }
  }

}
