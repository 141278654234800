import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class RightResponsibilityService {

  constructor(private appSettingsService: AppSettingsService, private httpClient: HttpClient) { }
  
  getRightResponsibilityHtml() {
    return this.appSettingsService.appSettings$.pipe(switchMap(appSettings => this.httpClient.get<{html: string}>(`${appSettings.apiBaseUrl}/v1/patientrightsandresponsibility`)));
  }
}
