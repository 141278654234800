// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateTimeOffset'})
export class DateTimeOffsetPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {

    }

    transform(value: string, format: string): string {
        let withoutOffset = value.split('.')[0];
        return this.datePipe.transform(withoutOffset, format);
    }
}