// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, OnInit, Input ,OnChanges,SimpleChanges} from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from 'src/app/core/http/product/product.service';


@Component({
  selector: 'app-image-display',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.scss']
})
export class ImageDisplayComponent implements OnInit, OnChanges {
  @Input() imageId
  @Input() className
  imageObject$: Observable<any>
constructor(private productService: ProductService) { }

  ngOnInit(): void {
    if (this.imageId) {
      this.imageObject$ = this.productService.getItemImage(this.imageId)
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
  }


}
