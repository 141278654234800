<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="push-notification">
    <h1 class="push-header">Send Push Notification To User</h1>
        <div>
            <mat-form-field style="width: 100%;">
                <mat-label>Select Messages</mat-label>
                <mat-select formControlName="messageSelect" (selectionChange)="onMessageSelectChange()">
                <mat-option [id]="study.value" *ngFor="let study of studies" [value]="study.value">
                    {{study.viewValue}}
                </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <textarea required (keyup)="onKeyup()"  placeholder="Enter message here." formControlName="messageText"></textarea>
        </div>
        <div style="color: red;" *ngIf="errorDuringSend">
            An error occurred while sending the push notification<ng-container *ngIf="!locationService.patientUserId">(s)</ng-container>.
        </div>
        <div style="color: red;" *ngIf="errorInvalidMessage">
            Message is required.
        </div>
        <div>
            <button id="sendMsgButton" mat-raised-button class="push-button primary">Send</button>
        </div>    
</form>