// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export enum DeviceMode {
    Active = 1,
    LowStim = 2,
    NoStim = 4,
    SystemTest = 8,
}

export function deviceModeOptions() {
    return Object.values(DeviceMode)
        .filter(key => !isNaN(Number(DeviceMode[key])))
        .map(option => ({key: option, value: DeviceMode[option]}));
}