// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { APIQuery } from 'src/app/shared/models/api-query.interface';
import { Clinic, ClinicCreate, ClinicDetails, ClinicSummary, ClinicUpdate } from 'src/app/shared/models/clinic';
import { ClinicianDetails } from 'src/app/shared/models/clinician';
import { PagedResult } from 'src/app/shared/models/paged-result.interface';

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  constructor(
    private readonly http: HttpClient,
    private readonly appSettingsService: AppSettingsService
  ) {}

  public query(apiQuery?: APIQuery, name?: string, otaFileRecordId?: number) {
    let params: any = {};

    if (!!name) {
      params.name = name;
    }
  

    if (!isNaN(otaFileRecordId)) {
      params.otaFileRecordId = otaFileRecordId;
    }

    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.get<PagedResult<Clinic>>(
          `${appSettings.apiBaseUrl}/v1.0/clinic/query`,
          { params }
        )
      )
    );
  }

  public queryDetails(
    apiQuery?: APIQuery,
    name?: string,
    facilityId?: string,
    phone?: string,
    city?: string,
    state?: string,
    otaFileRecordId?: number,
    isTestClinic?:boolean
  ) {
    let params: any = {};

    if (!!name) {
      params.name = name;
    }
    if (!!facilityId) {
      params.facilityId = facilityId;
    }
    if (!!phone) {
      params.phone = phone;
    }
    if (!!city) {
      params.city = city;
    }
    if (!!state) {
      params.state = state;
    }
    if(isTestClinic === true || isTestClinic === false) {
      params.isTestClinic = isTestClinic;
    }

    if (!!otaFileRecordId && !isNaN(otaFileRecordId)) {
      params.otaFileRecordId = otaFileRecordId;
    }

    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.get<PagedResult<ClinicDetails>>(
         `${appSettings.apiBaseUrl}/v1.0/clinic/query/details`,
          { params }
        )
      )
    );
  }

  public querySummary(apiQuery?: APIQuery) {
    let params = {};

    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.get<PagedResult<ClinicSummary>>(
          `${appSettings.apiBaseUrl}/v1.0/clinic/query/summary`,
          { params }
        )
      )
    );
  }

  public queryTable(
    apiQuery?: APIQuery,
    name?: string,
    facilityId?: string,
    phone?: string,
    city?: string,
    state?: string,
    otaFileRecordId?: number
  ) {
    let params: any = {};

    if (!!name) {
      params.name = name;
    }
    if (!!facilityId) {
      params.facilityId = facilityId;
    }
    if (!!phone) {
      params.phone = phone;
    }
    if (!!city) {
      params.city = city;
    }
    if (!!state) {
      params.state = state;
    }

    if (!isNaN(otaFileRecordId)) {
      params.otaFileRecordId = otaFileRecordId;
    }

    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.get<PagedResult<ClinicDetails>>(
         `${appSettings.apiBaseUrl}/v1.0/clinic/query/table`,
          { params }
        )
      )
    );
  }

  public queryClinics(apiQuery?: APIQuery) {
    let params = {};

    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.get<PagedResult<Clinic>>(
          `${appSettings.apiBaseUrl}/v1.0/clinic/query/clinics`,
          { params }
        )
      )
    );
  }

  public read(id: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.get<Clinic>(`${appSettings.apiBaseUrl}/v1.0/clinic/${id}`)
      )
    );
  }

  public delete(id: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.delete(`${appSettings.apiBaseUrl}/v1.0/clinic/${id}`)
      )
    );
  }

  public create(model: any) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.post<Clinic>(`${appSettings.apiBaseUrl}/v1.0/clinic`, model)
      )
    );
  }

  public update(id: number, model: ClinicUpdate) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<Clinic>(`${appSettings.apiBaseUrl}/v1.0/clinic/${id}`, model))
    );
  }

  public cliniciansQueryDetails(
    apiQuery?: APIQuery,
    clinicId?: number,
    studyId?: number,
    firstName?:string,
    lastName ?: string,
    phone?: string,
    officePhone?: string,
    clinicianId?: number,
    isPrescriber?: boolean
  ) {
    let params: any = {};

    if (apiQuery) {
      params = Object.assign({}, apiQuery, params);
    }

    if (!!clinicId) {
      params.clinicId = clinicId;
    }
    if (!!firstName) {
      params.firstName = firstName;
    }
    if (!!lastName) {
      params.lastName = lastName;
    }
    if (!!phone) {
      params.phone = phone;
    }
    if (!!officePhone) {
      params.officePhone = officePhone;
    }
    if (!!studyId) {
      params.studyId = studyId;
    }
    if (!!clinicianId) {
      params.clinicianId = clinicianId;
    }
    if (isPrescriber === true || isPrescriber === false) {
      params.isPrescriber = isPrescriber;
    }

    return this.appSettingsService.appSettings$.pipe(
      switchMap((appSettings) =>
        this.http.get<PagedResult<ClinicianDetails>>(
          `${appSettings.apiBaseUrl}/v1.0/clinic/clinicians/query/details`,
          { params }
        )
      )
    );
  }

  public resetEulaForClinicians(clinicId: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<number[]>(`${appSettings.apiBaseUrl}/v1.0/account/clinicians/reseteula/${clinicId}`, {}))
    );
  }

  public resetEulaForPatients(clinicId: number) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<number[]>(`${appSettings.apiBaseUrl}/v1.0/account/patients/reseteula/${clinicId}`, {}))
    );
  }
}
