<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="login-header">
    <button class="push-button back-button tertiary warrenty-back-btn back-btn-position" style="width: auto !important;" [routerLink]="['..']">
        <mat-icon svgIcon="back"></mat-icon>
    </button>
    <div class="logo-wrapper">
        <img src="/assets/icons/ic_vivally_logo.svg"/>
    </div>
</div>
<div class="delete-content">
    <!-- <img src="/assets/icons/ic_vivally_logo.svg" height="65px" width="auto"/> -->
    <div class="message">
        If you have a Vivally account, and you wish to delete it, please contact our Customer Care team at 
        <a href="mailto:customercare@avation.com">customercare&#64;avation.com</a>.
    </div>
</div>