<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div role="group" class="phone-number-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input integer class="phone-number-input-element" style="width: 30px"
         formControlName="area" size="3"
         maxLength="3"
         aria-label="Area code"
         (input)="_handleInput(parts.controls.area, exchange)"
         #area>
  <span class="phone-number-input-spacer">&ndash;</span>
  <input integer class="phone-number-input-element" style="width: 30px"
         formControlName="exchange"
         maxLength="3"
         size="3"
         aria-label="Exchange code"
         (input)="_handleInput(parts.controls.exchange, subscriber)"
         (keyup.backspace)="autoFocusPrev(parts.controls.exchange, area)"
         #exchange>
  <span class="phone-number-input-spacer">&ndash;</span>
  <input integer class="phone-number-input-element" style="width: 35px"
         formControlName="subscriber"
         maxLength="4"
         size="4"
         aria-label="Subscriber number"
         (input)="_handleInput(parts.controls.subscriber, extension)"
         (keyup.backspace)="autoFocusPrev(parts.controls.subscriber, exchange)"
         #subscriber>
  <small *ngIf="shouldLabelFloat && ext" style="padding-left: 5px; padding-right: 5px">ext</small>
  <input integer class="phone-number-input-element" [ngClass]="ext ? 'asfd' : 'hide'" style="width: 35px"
         formControlName="extension"
         maxLength="5"
         size="5"
         aria-label="Extension"
         (input)="_handleInput(parts.controls.extension)"
         (keyup.backspace)="autoFocusPrev(parts.controls.extension, subscriber)"
         #extension>
</div>
