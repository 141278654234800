<!--
 Copyright 2023, Avation Medical. All rights reserved.
 
 This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
 or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.
-->

<div class="report" id="report">
  <div class="dismiss-button" (click)="closeDialog()">
    <mat-icon class="close-icon" >close</mat-icon>
  </div>
    <div class="report-header">
        <div class="logo">
            <img class="logo-icon" src="../../../assets/icons/ic_logo.png">
        </div>
        <div class="center">
            <div class="title">Clinician Report</div>
            <div class="user-data">{{getUsername()}}</div>
        </div>
        <div class="metadata">
            <div><b>Date Created: </b>{{date | date: 'MMM d, yyyy h:mm a'}}</div>
            <div *ngIf="authService.getRoles().includes('Clinician')"><b>Clinic: </b>{{clinician.clinicName}}</div>
            <div *ngIf="authService.getRoles().includes('Clinician')"><b>Number of Patients: </b>{{numberOfPatients}}</div>
        </div>
    </div>

    <div class="body">
        <h1 class="title">Snapshot Report</h1>
        <div class="filter-display">{{reportService.getFilterStr()}}</div>
    </div>

    <table mat-table [dataSource]="dataSource" fixedLayout="true">

        <ng-container matColumnDef="userId">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>User ID</th>
            <td mat-cell *matCellDef="let row">{{ row.userId }}</td>
          </ng-container>

          <ng-container matColumnDef="enrollmentDate">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Enrollment Date</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.enrollmentDate">{{row.enrollmentDate | date : "mediumDate"}}</ng-container>
              <ng-container *ngIf="!row.enrollmentDate">N/A</ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="lastDiaryDate">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Last eDiary Entry</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.lastDiaryDate">{{row.lastDiaryDate | date : "mediumDate"}}</ng-container>
              <ng-container *ngIf="!row.lastDiaryDate">N/A</ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="firstName">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>First Name</th>
            <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Last Name</th>
            <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Full Name</th>
            <td mat-cell *matCellDef="let row">{{ row.firstName + ' ' + row.lastName }}</td>
          </ng-container>

          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Date of Birth</th>
            <td mat-cell *matCellDef="let row">{{ row.dateOfBirth | date : "mediumDate" }}</td>
          </ng-container>

          <ng-container matColumnDef="patientId">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Patient ID</th>
            <td mat-cell *matCellDef="let row">{{ row.patientId || row.subjectId || "N/A" }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let row">{{ row.email }}</td>
          </ng-container>

          <ng-container matColumnDef="patientPhone">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Patient Phone</th>
            <td mat-cell *matCellDef="let row"> {{ row.patientPhone | phoneNumber : row.extension }}</td>
          </ng-container>

          <ng-container matColumnDef="firstSessionTime">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>First Therapy Session</th>
            <td mat-cell *matCellDef="let row">{{ row.firstSessionTime | date : "mediumDate" }}</td>
          </ng-container>

          <ng-container matColumnDef="lastSessionTime">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Last Therapy Session</th>
            <td mat-cell *matCellDef="let row">{{ row.lastSessionTime | date : "mediumDate" }}</td>
          </ng-container>

          <ng-container matColumnDef="garmentSize">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Garment Size</th>
            <td mat-cell *matCellDef="let row">{{ row.garmentSize }}</td>
          </ng-container>

          <ng-container matColumnDef="clinicianName">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Clinician</th>
            <td mat-cell *matCellDef="let row">{{ row.clinicianName }}</td>
          </ng-container>

          <ng-container matColumnDef="clinicName">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Facility</th>
            <td mat-cell *matCellDef="let row">{{ row.clinicName }}</td>
          </ng-container>

          <ng-container matColumnDef="therapySchedule">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Therapy Schedule</th>
            <td mat-cell *matCellDef="let row">{{ row.therapySchedule | therapySchedule }}</td>
          </ng-container>

          <ng-container matColumnDef="foot">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Foot</th>
            <td mat-cell *matCellDef="let row">{{ row.foot | foot }}</td>
          </ng-container>

          <ng-container matColumnDef="completedSessions">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Completed Sessions</th>
            <td mat-cell *matCellDef="let row">{{ row.completedSessions }}</td>
          </ng-container>

          <ng-container matColumnDef="therapyCompliance">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef>Therapy Compliance</th>
            <td mat-cell *matCellDef="let row">{{ row.therapyCompliance | therapyCompliance : "1.0-0" }}</td>
          </ng-container>

          <ng-container matColumnDef="overallEMGPercent">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Overall EMG %</th>
            <td mat-cell *matCellDef="let row">{{ row.overallEMGPercent | percent : "1.0-0" }}</td>
          </ng-container>

          <ng-container matColumnDef="avgStimPW">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Avg Stim PW</th>
            <td mat-cell *matCellDef="let row">{{ row.avgStimPW | number : "1.0-0" }}</td>
          </ng-container>

          <ng-container matColumnDef="pwAtEMGDetect">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>PW At EMG Detect</th>
            <td mat-cell *matCellDef="let row">{{ row.pwAtEMGDetect | number : "1.0-0" }}</td>
          </ng-container>

          <ng-container matColumnDef="pwAtMaxComfort">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>PW At Max Comfort</th>
            <td mat-cell *matCellDef="let row">{{ row.pwAtMaxComfort | number : "1.0-0" }}</td>
          </ng-container>

          <ng-container matColumnDef="personalizationDate">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Personalization Date</th>
            <td mat-cell *matCellDef="let row">{{ row.lastScreeningTime | date : "mediumDate" }}</td>
          </ng-container>

          <ng-container matColumnDef="prescriptionDate">
            <th mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>Signed Rx Date</th>
            <td mat-cell *matCellDef="let row">{{ row.prescriptionDate | date : "mediumDate" }} </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length" style="text-align: center;">No data</td>
        </tr>
    </table>
</div>

<button id="exportButton" class="export-button" mat-raised-button (click)="reportService.exportReport()">Export</button>
