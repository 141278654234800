// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddConfirmDialogData, AddConfirmDialogResult } from '../../dialog-data.interface';
import { StatusPopupComponent } from '../status-popup/status-popup.component';


@Component({
  selector: 'app-add-confirm-dialog',
  templateUrl: './add-confirm-dialog.component.html',
  styleUrls: ['./add-confirm-dialog.component.scss']
})
export class AddConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddConfirmDialogData,
    private dialogRef: MatDialogRef<AddConfirmDialogComponent>,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dialogRef.beforeClosed().subscribe((result: AddConfirmDialogResult) => {
      if (result.success) {
        this.dialog.open(StatusPopupComponent , { data: {
          message: this.data.successMessage,
          buttonText: 'Close',
          color: false,
        }});
      }
      else if (result.response) {
        let message: any = "";
        Object.values(result.response.error).forEach(value => message = value);
        this.dialog.open(StatusPopupComponent , { data: {
          message: message,
          buttonText: 'Close',
          color: true,
        }});
      }
    })
  }

  submit() {
    this.data.http.toPromise().then(record => {
      this.dialogRef.close({ success: true, response: null, record: record } as AddConfirmDialogResult);
    }).catch(response => {
      this.dialogRef.close({ success: false, response: response } as AddConfirmDialogResult);
    });
  }

  cancel() {
    this.dialogRef.close({ success: false, response: null });
  }
}
