// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from "chart.js";
import { Label } from 'ng2-charts';
import { datesBetween } from 'src/app/shared/helpers/date-utils';

@Component({
  selector: 'patient-therapy-graph',
  templateUrl: './patient-therapy-graph.component.html',
})
export class PatientTherapyGraphComponent implements OnInit, OnChanges {
  public chartType: string = 'line';
  public chartData: ChartDataSets[];
  public chartLabels: Label[];
  public chartOptions: (ChartOptions);
  public chartPlugins = [];

  @Input('data')
  data: any[];

  constructor(
    private readonly datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.createChart();
  }

  ngOnChanges(changes) {
    if (changes.data) {
      this.createChart();
    }
  }

  createChart() {
    var data = this.data.slice().filter(row => !row.isScreening).reduce((acc, session: any) => {
      let date = this.datePipe.transform(session.timestamp, 'MM/d/yyyy');
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(session);
      return acc;
    }, {});

    var dates: Array<Date> = Object.keys(data).map(date => new Date(date)).sort((a, b) => {
      return a >b ? 1 : -1;
    });
    var minDate: Date = dates[0];
    var maxDate: Date = dates[dates.length - 1];

    var allDates = datesBetween(minDate, maxDate);
    var dateStrs = new Array();
    
    var targetSessions: Array<number> = [];
    var actualSessions: Array<number> = [];
    var pointRadii: Array<number> = [];
    var lastSchedule: number = 0;
    var lastTarget: number = 0;
    var lastActual: number = 0;
    var day: number = 0;

    allDates.forEach(date => {
      const dateStr = this.datePipe.transform(date, 'MM/d/yyyy');
      dateStrs.push(dateStr);
      
      if (dateStr in data) {
        actualSessions.push(data[dateStr].filter(x => x.isComplete).length + lastActual);
        pointRadii.push(3);  // Default point radius
        lastSchedule = data[dateStr][0].therapySchedule;
      }
      else {
        actualSessions.push(lastActual);
        pointRadii.push(0);
      }

      lastActual = actualSessions[actualSessions.length - 1];

      if (day % 7 === 0) {
        targetSessions.push(lastSchedule + lastTarget);
      }
      else {
        targetSessions.push(lastTarget);
      }

      lastTarget = targetSessions[targetSessions.length - 1];
      day++;
    });
    
    this.chartLabels = dateStrs.map(date => new Date(date).toISOString());
    this.chartData = [
      {
        data: targetSessions,
        label: 'Target Sessions',
        lineTension: 0,
        borderColor: '#61A12C',
        backgroundColor: '#61A12C',
        pointBackgroundColor: '#61A12C',
        pointRadius: pointRadii,
        fill: false,
      },
      {
        data: actualSessions,
        label: 'Actual Sessions',
        lineTension: 0,
        borderColor: '#C4DC8F',
        backgroundColor: '#C4DC8F',
        pointBackgroundColor: '#C4DC8F',
        pointRadius: pointRadii,
        fill: false,
      },
    ];
    this.chartOptions = {
      responsive: true,
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: true,
        position: 'top',
        text: 'Target Vs Actual Sessions',
        fontSize: 15,
      },
      scales: {
        yAxes: [{
          ticks: {
            precision: 0,
          },
          scaleLabel: {
            display: true,
            labelString: 'Number of Sessions',
          }
        }],
        xAxes: [{
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM DD',
            }
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20
          }
        }]
      },
    };

    this.chartPlugins = [{
      // ChartJS background color is transparent by default. This sets it to white for the pdf export
      beforeDraw: function(chartInstance) {
        var ctx = chartInstance.ctx;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, chartInstance.width, chartInstance.height);
      }
    }];
  }
}