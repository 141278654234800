import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rx-popup',
  templateUrl: './rx-popup.component.html',
  styleUrls: ['./rx-popup.component.scss']
})
export class RxPopupComponent implements OnInit {
  title: string;
  prescribers: any;
  footerTitle: string;
  onClick: any;
  isSinglePrescriber: boolean;
  selectedPrescriber: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RxPopupComponent>,
    private readonly dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  rxFormGroup: FormGroup = this.formBuilder.group({
    prescriber: ['', [Validators.required]],
  })

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.prescribers = this.data.prescribers;
      this.footerTitle = this.data.footerTitle;
      this.selectedPrescriber = this.data.selectedPrescriber;

      if (this.data.selectedPrescriber) {
        this.rxFormGroup.patchValue({prescriber: this.data.selectedPrescriber});
      }
      if (this.prescribers.length === 1) {
        this.rxFormGroup.patchValue({prescriber: this.prescribers[0].userId});
        this.rxFormGroup.get('prescriber').disable();
      }
    }
  }
  onSubmit() {
    this.data.onClick(this.rxFormGroup.value.prescriber);
  }

}
