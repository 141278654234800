// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { ChangePasswordModel, ConfirmAccessCodeModel, ForgotPasswordModel } from 'src/app/shared/models/login';
import { AuthService } from '../../services/auth.service';

export interface AccountLoginResponse {
  isAuthSuccessful: boolean;
  errorMessage: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private readonly http: HttpClient,
    private readonly appSettingsService: AppSettingsService,
    private readonly authService: AuthService,
  ) { }
  
  public login(email: string, password: string) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post<AccountLoginResponse>(`${appSettings.apiBaseUrl}/v1/account/login`, { email, password }))
    ) 
  }

  public changePassword(model: ChangePasswordModel) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put(`${appSettings.apiBaseUrl}/v1/account/changePassword`, model))
    );    
  }

  public sendForgotPassword(model: ForgotPasswordModel) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings =>this.http.post(`${appSettings.apiBaseUrl}/v1/account/forgotPassword`, model))
    );
  }

  public confirmForgotPassword(model: ConfirmAccessCodeModel) {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.post(`${appSettings.apiBaseUrl}/v1/account/confirmForgotPassword`, model))
    );
  }

  public resetEula(userIds: number[]): Observable<number[]> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.put<number[]>(`${appSettings.apiBaseUrl}/v1.0/account/reseteula`, {userIds}))
    )
  }

  
  private getDuplicatePhoneNumbersReport(): Observable<ArrayBuffer> {
    return this.appSettingsService.appSettings$.pipe(
      switchMap(appSettings => this.http.get(`${appSettings.apiBaseUrl}/v1.0/account/duplicatephonenumbersreport`, { responseType: 'arraybuffer'}))
    )
  }
  
  public async exportDuplicatePhoneNumbersReportXlsx(): Promise<void> {
    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api      
    
    var xlsxBuffer = await this.getDuplicatePhoneNumbersReport().toPromise();

    var xlsxBlob = new Blob([xlsxBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const data = window.URL.createObjectURL(xlsxBlob);

    let link = document.createElement('a');
    link.href = data;

    let now = new Date();

    link.download = `duplicate_phone_numbers_${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()}.xlsx`;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
