// Copyright 2023, Avation Medical. All rights reserved.
// 
// This code is proprietary and confidential information of Avation Medical. Any use, reproduction, modification
// or distribution of the code without the express prior written consent of Avation Medical is strictly prohibited.

export const environment = {
  production: false,
  apiBaseUrl: 'https://localhost:5001',
  appId:  '',
  locationId:''
};